<template>
  <header class="w-full bg-white tracking-wide text-nowrap">
    <!-- for small screen -->
    <div
      class="flex justify-between items-center text-white min-[1200px]:hidden bg-green px-5 py-2.5"
    >
      <button @click="toggleDrawer()" class="border border-white px-2 py-1">
        <font-awesome-icon icon="fa-solid fa-bars" class="text-sm md:text-xl" />
      </button>
      <button @click="toggleLanguage()" class="text-sm md:text-base">
        <font-awesome-icon icon="fa-solid fa-globe" class="mr-1" />{{
          lang === "mn" ? "Монгол" : "English"
        }}
      </button>
    </div>

    <transition name="fade" class="min-[1200px]:hidden">
      <div
        v-if="isDrawerOpen"
        class="fixed top-0 left-0 z-20 h-screen w-[18rem] bg-green text-white text-sm p-5"
      >
        <button @click="toggleDrawer()" class="absolute top-3 right-3">
          <font-awesome-icon icon="fa-solid fa-xmark" class="text-xl" />
        </button>

        <ul class="mt-10">
          <li
            v-for="parent in mainCategories"
            :key="parent.cid"
            class="flex flex-col border-b border-slate-200 py-3"
          >
            <div
              class="flex justify-between items-center w-full cursor-pointer"
            >
              <router-link
                :to="getCategoryLink(parent)"
                @click="toggleDrawer()"
                class="w-full"
              >
                {{ lang === "mn" ? parent.category : parent.category_en }}
              </router-link>
              <button
                v-if="hasChildCategories(parent.cid)"
                @click="selectCat(parent)"
                class="w[5rem]"
              >
                <font-awesome-icon
                  :icon="
                    selectedCat && selectedCat.cid === parent.cid
                      ? 'fa-solid fa-angle-down'
                      : 'fa-solid fa-angle-right'
                  "
                  class="text-sm"
                />
              </button>
            </div>

            <ul
              v-if="
                selectedCat &&
                selectedCat.cid === parent.cid &&
                hasChildCategories(parent.cid)
              "
              class="pl-3 mt-2"
            >
              <li
                v-for="child in childCategories.filter(
                  (child) => child.pid === parent.cid
                )"
                :key="child.cid"
                class="text-wrap hover:bg-lightGreen"
              >
                <router-link
                  :to="getChildCategoryLink(parent, child)"
                  @click="toggleDrawer()"
                  class="block"
                >
                  {{ lang === "mn" ? child.category : child.category_en }}
                </router-link>

                <ul class="pl-3 my-3">
                  <li
                    v-for="secondChild in childCategories.filter(
                      (secondChild) => secondChild.pid === child.cid
                    )"
                    :key="secondChild.cid"
                    class="text-wrap hover:bg-lightGreen"
                  >
                    <router-link
                      :to="`/mm/page/${secondChild.cid}`"
                      @click="toggleDrawer()"
                      class="block"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-circle"
                        class="mr-1 text-xs"
                      />
                      {{
                        lang === "mn"
                          ? secondChild.category
                          : secondChild.category_en
                      }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li class="flex flex-col border-b border-slate-200 py-3">
            <router-link
              to="/mm/self-assessment"
              @click="toggleDrawer()"
              class="w-full"
            >
              {{ lang === "mn" ? "ХУУ үнэлгээ" : "Self Assessment" }}
            </router-link>
          </li>

          <li class="flex flex-col border-b border-slate-200 py-3">
            <router-link to="/mm/trade" @click="toggleDrawer()" class="w-full">
              {{ lang === "mn" ? "Арилжааны мэдээлэл" : "Trade Report" }}
            </router-link>
          </li>
        </ul>
      </div>
    </transition>

    <!-- for large screen -->
    <nav class="hidden min-[1200px]:flex flex-col items-center bg-green">
      <ul
        class="flex justify-between items-center min-[1350px]:w-4/5 max-[1349px]:px-3 uppercase text-white text-sm py-3"
      >
        <li
          class="flex justify-center items-center text-center border-l border-white px-3 py-2 hover:bg-lightGreen"
          :class="isRoute === `/mm` ? 'bg-lightGreen' : ''"
        >
          <router-link
            to="/mm"
            class="flex justify-center items-center w-full h-full"
          >
            <font-awesome-icon icon="fa-solid fa-house" />
          </router-link>
        </li>
        <li
          v-for="parent in mainCategories"
          :key="parent.cid"
          class="flex justify-center items-center w-full text-center group relative border-l border-white px-3 py-1 hover:bg-lightGreen"
          :class="
            isRoute === `/mm/category/${parent.cid}` ||
            isRoute.startsWith(`/mm/blog/${parent.cid}`)
              ? 'bg-lightGreen'
              : ''
          "
        >
          <router-link
            :to="getCategoryLink(parent)"
            class="flex justify-center items-center w-full h-full"
          >
            {{ lang === "mn" ? parent.category : parent.category_en }}
            <font-awesome-icon
              v-if="hasChildCategories(parent.cid)"
              icon="fa-solid fa-angle-down"
              class="text-xs ml-0.5"
            />
          </router-link>

          <ul
            class="invisible group-hover:visible absolute top-7 left-0 z-20 block bg-green w-full text-pretty"
          >
            <li
              v-for="child in childCategories.filter(
                (child) => child.pid === parent.cid
              )"
              :key="child.cid"
              class="relative p-3 border-b border-gray-200 hover:bg-lightGreen group"
            >
              <a
                v-if="child.cid === 14"
                href="https://pdacmongolia.com"
                target="_blank"
              >
                {{ lang === "mn" ? child.category : child.category_en }}
              </a>
              <a
                v-else-if="child.cid === 16"
                href="https://miningweek.mn"
                target="_blank"
              >
                {{ lang === "mn" ? child.category : child.category_en }}
              </a>
              <router-link v-else :to="getChildCategoryLink(parent, child)">
                {{ lang === "mn" ? child.category : child.category_en }}
              </router-link>
            </li>
          </ul>
        </li>
        <li
          class="flex justify-center items-center text-center border-x border-white px-3 py-2 hover:bg-lightGreen"
        >
          <button
            @click="toggleMoreMenu()"
            class="flex justify-center items-center w-full h-full"
          >
            <font-awesome-icon icon="fa-solid fa-ellipsis" />
          </button>
        </li>
      </ul>

      <!-- MORE MENU -->
      <ul
        v-if="isMoreMenu"
        class="flex items-center min-[1350px]:w-4/5 max-[1349px]:px-3 uppercase text-white text-sm py-3"
      >
        <li
          v-for="parent in subCategories"
          :key="parent.cid"
          class="flex justify-center items-center text-center group relative border-l border-white px-3 py-1 hover:bg-lightGreen"
          :class="
            isRoute === `/mm/category/${parent.cid}` ||
            isRoute.startsWith(`/mm/blog/${parent.cid}`)
              ? 'bg-lightGreen'
              : ''
          "
        >
          <router-link :to="`/mm/category/${parent.pid}#${parent.category}`">
            {{ lang === "mn" ? parent.category : parent.category_en }}
            <font-awesome-icon
              icon="fa-solid fa-angle-down"
              class="text-xs ml-1"
            />
          </router-link>
          <ul
            class="invisible group-hover:visible absolute top-7 left-0 z-[1200] block bg-green w-full text-pretty"
          >
            <li
              v-for="child in childCategories.filter(
                (child) => child.pid === parent.cid
              )"
              :key="child.cid"
              class="relative p-3 border-b border-gray-200 hover:bg-lightGreen group"
            >
              <router-link :to="`/mm/page/${child.cid}`">
                {{ lang === "mn" ? child.category : child.category_en }}
              </router-link>
            </li>
          </ul>
        </li>

        <li
          class="flex justify-center items-center text-center border-l border-white px-3 py-1 hover:bg-lightGreen"
          :class="isRoute === '/mm/self-assessment' ? 'bg-lightGreen' : ''"
        >
          <router-link to="/mm/self-assessment">{{
            lang === "mn" ? "Үнэлгээ" : "Assessment"
          }}</router-link>
        </li>
        <li
          class="flex justify-center items-center text-center border-x border-white px-3 py-1 hover:bg-lightGreen"
          :class="isRoute === '/mm/trade' ? 'bg-lightGreen' : ''"
        >
          <router-link to="/mm/trade">{{
            lang === "mn" ? " Арилжааны мэдээлэл" : "Trade Report"
          }}</router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import clientInstance from "@/lib/clientInstance";
import { onMounted, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "MMNavbar",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const route = useRoute();
    const isRoute = computed(() => route.path);

    const isDrawerOpen = ref(false);
    const toggleDrawer = () => {
      isDrawerOpen.value = !isDrawerOpen.value;
    };

    const mainCategories = ref([]);
    const childCategories = ref([]);
    const subCategories = ref([]);
    onMounted(async () => {
      try {
        const res = await clientInstance.get("/get/category");
        mainCategories.value = res.data.mainCategories;
        childCategories.value = res.data.childCategories;

        const resFooter = await clientInstance.get("/get/footer-category");
        subCategories.value = resFooter.data.subCategories;
      } catch (err) {
        return err;
      }
    });

    const hasChildCategories = (parentId) => {
      return childCategories.value.some((child) => child.pid === parentId);
    };

    const selectedCat = ref(null);
    const selectCat = (parent) => {
      if (selectedCat.value && selectedCat.value.cid === parent.cid) {
        selectedCat.value = null;
      } else {
        selectedCat.value = parent;
      }
    };

    const getCategoryLink = (category) => {
      if (category.page_type === "static") {
        return `/mm/category/${category.cid}`;
      } else if (category.page_type === "news") {
        return `/mm/blog/${category.cid}=${1}`;
      } else {
        return `/mm/category/${category.cid}`;
      }
    };

    const getChildCategoryLink = (parentCategory, childCategory) => {
      if (parentCategory.page_type === "static") {
        return `/mm/category/${parentCategory.cid}#${childCategory.category}`;
      } else if (parentCategory.page_type === "news") {
        return `/mm/blog/${childCategory.cid}=${1}`;
      } else {
        return `/mm/category/${parentCategory.cid}#${childCategory.category}`;
      }
    };

    const toggleLanguage = () => {
      store.dispatch("toggleLanguage");
    };

    const isMoreMenu = ref(false);
    const toggleMoreMenu = () => {
      isMoreMenu.value = !isMoreMenu.value;
    };

    return {
      lang,
      isDrawerOpen,
      toggleDrawer,
      selectedCat,
      selectCat,
      mainCategories,
      childCategories,
      hasChildCategories,
      getCategoryLink,
      getChildCategoryLink,
      toggleLanguage,
      subCategories,
      isRoute,
      isMoreMenu,
      toggleMoreMenu,
    };
  },
};
</script>
