import adminInstance from "./adminInstance";

export const adminAuth = async () => {
  try {
    const res = await adminInstance.get("/auth");
    if (!res) return false;
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    if (!err.response) return false;
    if (err.response.status === 401) {
      return false;
    }
    console.error(err);
  }
};
