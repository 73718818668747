<template>
  <div
    class="flex flex-col justify-center items-center gap-5 border border-gray-200 shadow py-3 px-8"
  >
    <div class="flex items-center w-full">
      <hr class="border-2 border-green w-[1.5rem] mr-1" />
      <hr class="border-2 border-green w-[0.3rem] mr-3" />
      <h1 class="capitalize text-lg">
        {{ lang === "mn" ? title : title_en }}
      </h1>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "SideContainer",
  props: {
    title: String,
    title_en: String,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    return { lang };
  },
};
</script>
