<template>
  <div class="flex flex-col items-center justify-center h-screen bg-gray-100">
    <h1 class="text-2xl sm:text-4xl font-bold text-gray-800 mb-4">
      Page Not Found
    </h1>
    <p class="text-gray-600 text-center">
      УУЧЛААРАЙ, ТАНЫ ХАЙСАН ХУУДАС ОЛДСОНГҮЙ.
    </p>
    <a href="/mm" class="mt-8 text-blue-500 hover:underline"
      >Нүүр хуудасруу буцах</a
    >
  </div>
</template>
