<template>
  <AdminNavbar>
    <h1 class="font-bold text-lg">Уул Уурхай 101 - Бичлэгнүүд</h1>
    <div class="bg-white rounded shadow">
      <div
        class="flex justify-between items-center border-t-4 border-t-blue-500 rounded-t border-b p-3"
      >
        <h6 class="text-base">Бичлэг</h6>
        <button
          v-if="isEditingMode"
          @click="cancelEditMode()"
          class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600"
        >
          <font-awesome-icon icon="fa-solid fa-angles-left" class="mr-1" />
          Буцах
        </button>
      </div>

      <div v-if="!isEditingMode" class="p-3 space-y-5">
        <div v-if="videos.length > 0 && !isLoading" class="space-y-5">
          <div class="flex justify-between items-center text-xs">
            <div class="space-x-3">
              <span>Хуудас бүрт:</span>
              <select
                name="dataPerPage"
                id="dataPerPage"
                class="border rounded p-1 text-blue-500"
                v-model="dataPerPage"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
            </div>

            <span>Нийт бичлэгний тоо: {{ totalVideoCount }} </span>

            <div class="flex items-center gap-3">
              <span>Нийт хуудас: {{ totalPages }} </span>
              <PaginationCart
                :totalPages="totalPages"
                :modelValue="currentPage"
                :style="{
                  color: 'blue-500',
                  height: 7,
                  width: 7,
                  fontSize: 'xs',
                }"
                @page-changed="onPageChanged"
              />
            </div>
          </div>
          <hot-table :settings="hotSettings" :data="videos"></hot-table>
        </div>

        <div
          v-else-if="videos.length === 0 && !isLoading"
          class="flex justify-center items-center h-[40vh]"
        >
          Жагсаалт хоосон байна
        </div>

        <div v-else class="flex justify-center items-center h-[40vh]">
          <SpinLoading :color="'rgb(59 130 246)'" />
        </div>

        <!-- ADD FILE -->
        <div class="rounded shadow w-2/3">
          <h6 class="border-b p-3">Бичлэг нэмэх</h6>

          <div v-if="!isInsertLoading" class="text-sm">
            <div class="flex items-center p-3">
              <label for="title" class="font-bold w-1/4"
                >Бичлэгний нэр<span class="text-red-500">*</span></label
              >
              <input
                type="text"
                id="title"
                name="title"
                class="border rounded p-2 w-full"
                placeholder="Бичлэгний нэр"
                v-model="title"
              />
            </div>

            <div class="flex items-center px-3 pb-3">
              <label for="video" class="font-bold w-1/4"
                >Бичлэг<span class="text-red-500">*</span></label
              >
              <div class="block w-full">
                <input
                  type="file"
                  id="video"
                  name="video"
                  class="border rounded p-2 w-full"
                  accept="video/mp4"
                  @change="handleFileUpload"
                />
                <p class="text-sky-500 text-xs">
                  Зөвхөн mp4 өргөтгөлтэй, 300MB-аас бага хэмжээтэй файл оруулна
                  уу.
                </p>
              </div>
            </div>

            <div class="flex bg-gray-200 gap-5 p-3 text-xs text-white">
              <button
                @click="addVideo()"
                class="p-2 bg-[#28a745] hover:bg-[#208537]"
              >
                <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />Бичлэг
                нэмэх
              </button>

              <button
                @click="resetForm"
                class="p-2 bg-orange-400 hover:bg-orange-500"
              >
                <font-awesome-icon
                  icon="fa-solid fa-eraser"
                  class="mr-1"
                />Арилгах
              </button>
            </div>
          </div>

          <div v-else class="flex justify-center items-center h-[20vh]">
            <SpinLoading :color="'rgb(59 130 246)'" />
          </div>
        </div>
      </div>

      <div v-else class="p-3 space-y-3">
        <div class="rounded shadow w-2/3">
          <h6 class="border-b p-3">Бичлэг засварлах</h6>

          <div class="text-sm">
            <div class="flex items-center p-3">
              <label for="title" class="font-bold w-[9rem]"
                >Бичлэгний нэр<span class="text-red-500">*</span></label
              >
              <input
                type="text"
                id="title"
                name="title"
                class="border rounded p-2 w-full"
                placeholder="Файлын нэр"
                v-model="title"
              />
            </div>

            <div class="flex bg-gray-200 gap-5 p-3 text-xs text-white">
              <button
                @click="updateVideo()"
                class="p-2 bg-sky-500 hover:bg-sky-600"
              >
                <font-awesome-icon icon="fa-solid fa-pen" class="mr-1" />Нэр
                өөрчлөх
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AdminNavbar>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import adminInstance from "@/lib/adminInstance";
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import PaginationCart from "@/components/ui/PaginationCart.vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";

registerAllModules();

export default {
  name: "MMMiningVideo",
  components: {
    AdminNavbar,
    SpinLoading,
    PaginationCart,
    HotTable,
  },
  setup() {
    const isLoading = ref(false);
    const isInsertLoading = ref(false);
    const dataPerPage = ref(10);
    const totalVideoCount = ref(0);
    const totalPages = ref(1);
    const currentPage = ref(1);
    const videos = ref([]);

    const fetchMiningVideos = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get(
          `/get/mining-video?page=${currentPage.value}&limit=${dataPerPage.value}`
        );
        if (res.status === 200) {
          videos.value = res.data.video;
          totalPages.value = res.data.totalPages;
          totalVideoCount.value = res.data.total;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchMiningVideos();
    });

    watch([() => dataPerPage.value], () => {
      currentPage.value = 1;
      fetchMiningVideos();
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchMiningVideos();
    };

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center w-full p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const hotSettings = {
      colHeaders: ["Үйлдэл", "Бичлэгний нэр", "Бичлэг"],
      columns: [
        {
          renderer: function (inst, td, row) {
            const video = videos.value[row];
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-2 gap-2 text-white">
                      <button class="flex items-center bg-sky-500 py-1 px-2 rounded edit-btn">
                          <svg class="fill-current w-3 h-3 mr-1" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
                          <span>Нэр өөрчлөх</span>
                      </button>
                      <button class="flex justify-center items-center bg-red-500 py-1 px-2 rounded delete-btn">
                          <svg class="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
                          <span>Устгах</span>
                      </button>
                  </div>`;

            const editButton = td.querySelector(".edit-btn");
            if (editButton) {
              editButton.addEventListener("click", () => {
                showEditMode(video);
              });
            }

            const deleteButton = td.querySelector(".delete-btn");
            if (deleteButton) {
              deleteButton.addEventListener("click", () => {
                deleteVideo(video.id);
              });
            }
          },
        },
        {
          data: "title",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "video",
          readOnly: true,
          renderer: commonRenderer,
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "50vh",
      fixedColumnsLeft: 1,
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const deleteVideo = async (videoId) => {
      if (!window.confirm(`Бичлэгийг устгах уу?`)) {
        return;
      }
      try {
        const res = await adminInstance.delete(
          `/delete/mining-video?id=${videoId}`
        );
        if (res.status === 200) {
          await fetchMiningVideos();
        }
      } catch (err) {
        return err;
      }
    };

    // ADD VIDEO
    const title = ref(null);
    const video = ref(null);

    const resetForm = () => {
      title.value = null;
      video.value = null;

      const fileInput = document.getElementById("video");
      if (fileInput) {
        fileInput.value = "";
      }
    };

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      const maxFileSize = 300 * 1024 * 1024;

      if (file && file.type === "video/mp4") {
        if (file.size > maxFileSize) {
          alert("Файл хэтэрхий том байна. 300MB-с бага файлыг сонгоно уу.");
          video.value = null;
          event.target.value = "";
        } else {
          video.value = file;
        }
      } else {
        alert("Зөвхөн MP4 файл сонгоно уу.");
        video.value = null;
        event.target.value = "";
      }
    };

    const isValid = () => {
      if (!title.value) return alert("Бичлэгний нэрийг оруулна уу");
      if (!video.value) return alert("Бичлэг оруулна уу");

      return true;
    };

    const addVideo = async () => {
      if (!isValid()) return;

      const formData = new FormData();
      formData.append("title", title.value);
      formData.append("video", video.value);

      try {
        isInsertLoading.value = true;
        const res = await adminInstance.post("/insert/mining-video", formData);
        if (res.status === 200) {
          await fetchMiningVideos();
          resetForm();
        }
      } catch (err) {
        return err;
      } finally {
        isInsertLoading.value = false;
      }
    };

    //EDIT
    const id = ref(null);
    const isEditingMode = ref(false);
    const showEditMode = (video) => {
      isEditingMode.value = true;
      id.value = video.id;
      title.value = video.title;
    };
    const cancelEditMode = () => {
      isEditingMode.value = false;
      id.value = null;
      title.value = null;
    };

    const updateVideo = async () => {
      if (!title.value) return alert("Бичлэгний нэрийг оруулна уу");

      try {
        if (id.value && title.value) {
          const res = await adminInstance.post("/update/mining-video", {
            id: id.value,
            title: title.value,
          });
          if (res.status === 200) window.location.reload();
        }
      } catch (err) {
        return err;
      }
    };

    return {
      isLoading,
      videos,
      hotSettings,
      resetForm,
      addVideo,
      title,
      video,
      handleFileUpload,
      isEditingMode,
      updateVideo,
      cancelEditMode,
      dataPerPage,
      totalVideoCount,
      totalPages,
      currentPage,
      onPageChanged,
      isInsertLoading,
    };
  },
};
</script>
