<template>
  <AdminNavbar>
    <h1 class="font-bold text-lg">Сошиал холбоос</h1>
    <!-- Add Social Url -->
    <div class="bg-white rounded shadow">
      <h6 class="text-base border-t-4 border-t-blue-500 rounded-t border-b p-3">
        Сошиал холбоос
      </h6>

      <div class="p-3 space-y-5">
        <div class="rounded shadow">
          <h6 class="border-b p-3">Жагсаалт</h6>
          <div v-if="socialLinks.length > 0 && !isLoading" class="p-3">
            <hot-table :settings="hotSettings" :data="socialLinks"></hot-table>
          </div>
          <div
            v-else-if="socialLinks.length === 0 && !isLoading"
            class="flex justify-center items-center h-[40vh]"
          >
            Жагсаалт хоосон байна
          </div>

          <div v-else class="flex justify-center items-center h-[40vh]">
            <SpinLoading :color="'rgb(59 130 246)'" />
          </div>
        </div>

        <div class="rounded shadow w-2/3">
          <h6 class="border-b p-3">Нэмэх / Засварлах</h6>

          <div class="text-sm">
            <div class="relative flex items-center p-3">
              <h6 for="social_icon" class="font-bold w-[8rem]">Icon</h6>
              <button
                @click="openIcons"
                class="flex items-center border rounded w-full"
                :class="selectedLink.icon ? 'p-2' : 'p-3'"
              >
                <font-awesome-icon
                  v-if="!isIconShowing"
                  icon="fa-solid fa-caret-down"
                  class="mr-3"
                />
                <font-awesome-icon
                  v-else
                  icon="fa-solid fa-caret-up"
                  class="mr-3"
                />
                <font-awesome-icon
                  :icon="selectedLink.icon"
                  class="text-green text-xl"
                />
              </button>
              <div
                v-if="isIconShowing"
                class="absolute top-12 left-28 z-20 rounded shadow-xl bg-gray-50 p-3 w-[15rem]"
              >
                <div class="flex justify-end mb-3">
                  <button
                    @click="openIcons"
                    class="text-gray-500 text-xs hover:text-red-500"
                  >
                    <font-awesome-icon icon="fa-solid fa-x" />
                  </button>
                </div>
                <div class="grid grid-cols-6 gap-3 text-xl">
                  <button
                    v-for="icon in socialIcons"
                    :key="icon"
                    class="rounded hover:bg-gray-200"
                    @click="selectIcon(icon)"
                  >
                    <font-awesome-icon :icon="icon" class="text-green" />
                  </button>
                </div>
              </div>
            </div>

            <div class="flex items-center px-3 pb-3">
              <label for="social" class="font-bold w-[8rem]">Линк</label>
              <input
                type="text"
                class="border rounded p-2 w-full"
                v-model="selectedLink.link"
              />
            </div>

            <div class="flex bg-gray-200 gap-5 p-3 text-xs text-white">
              <button
                @click="uploadSocialLink()"
                class="p-2"
                :class="
                  selectedLink.id
                    ? 'bg-sky-500 hover:bg-sky-600'
                    : 'bg-[#28a745] hover:bg-[#208537]'
                "
              >
                <font-awesome-icon
                  :icon="
                    selectedLink.id ? 'fa-solid fa-pen' : 'fa-solid fa-add'
                  "
                  class="mr-1"
                />{{ selectedLink.id ? "Холбоос засварлах" : "Холбоос нэмэх" }}
              </button>

              <button
                @click="resetForm"
                class="p-2 bg-orange-400 hover:bg-orange-500"
              >
                <font-awesome-icon
                  icon="fa-solid fa-eraser"
                  class="mr-1"
                />Арилгах
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AdminNavbar>
</template>

<script>
import { onMounted, ref } from "vue";
import adminInstance from "@/lib/adminInstance";
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";

registerAllModules();

export default {
  name: "MMSocialLinks",
  components: {
    AdminNavbar,
    SpinLoading,
    HotTable,
  },
  setup() {
    const isLoading = ref(false);
    const socialLinks = ref([]);
    const socialIcons = [
      "fa-brands fa-facebook-f",
      "fa-brands fa-x-twitter",
      "fa-brands fa-snapchat",
      "fa-brands fa-linkedin-in",
      "fa-brands fa-pinterest",
      "fa-brands fa-tiktok",
      "fa-brands fa-youtube",
      "fa-brands fa-instagram",
      "fa-brands fa-weixin",
      "fa-brands fa-telegram",
      "fa-brands fa-discord",
      "fa-brands fa-google",
      "fa-brands fa-dropbox",
      "fa-brands fa-whatsapp",
      "fa-brands fa-vimeo",
      "fa-brands fa-spotify",
      "fa-brands fa-weibo",
      "fa-brands fa-viber",
      "fa-brands fa-threads",
      "fa-brands fa-steam",
    ];

    const fetchSocialLinks = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get("/get/social-link");
        if (res.status === 200) socialLinks.value = res.data.link;
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchSocialLinks();
    });

    const selectedLink = ref({
      id: "",
      icon: "",
      link: "",
    });

    const selectIcon = (icon) => {
      selectedLink.value.icon = icon;
    };

    const isIconShowing = ref(false);
    const openIcons = () => {
      isIconShowing.value = !isIconShowing.value;
    };

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center w-full p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const hotSettings = {
      colHeaders: ["Үйлдэл", "Icon", "Холбоос"],
      columns: [
        {
          renderer: function (inst, td, row) {
            const link = socialLinks.value[row];
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-2 gap-2 text-white">
                  <button class="flex items-center bg-sky-500 py-1 px-2 rounded edit-btn">
                      <svg class="fill-current w-3 h-3 mr-1" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
                      <span>Засварлах</span>
                  </button>
                  <button class="flex justify-center items-center bg-red-500 py-1 px-2 rounded delete-btn">
                      <svg class="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
                      <span>Устгах</span>
                  </button>
              </div>`;

            const editButton = td.querySelector(".edit-btn");
            if (editButton) {
              editButton.addEventListener("click", () => {
                editSocialLink(link);
              });
            }

            const deleteButton = td.querySelector(".delete-btn");
            if (deleteButton) {
              deleteButton.addEventListener("click", () => {
                deleteSocialLink(link.id);
              });
            }
          },
        },
        {
          data: "icon",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "link",
          readOnly: true,
          renderer: commonRenderer,
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "35vh",
      fixedColumnsLeft: 1,
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const editSocialLink = (link) => {
      selectedLink.value.id = link.id;
      selectedLink.value.icon = link.icon;
      selectedLink.value.link = link.link;
    };

    const isValid = () => {
      if (!selectedLink.value.icon) return alert("Icon-ийг сонгоно уу");
      if (!selectedLink.value.link) return alert("Линкийг оруулна уу");

      return true;
    };

    const uploadSocialLink = async () => {
      if (!isValid()) return;

      try {
        if (selectedLink.value.id) {
          const res = await adminInstance.post(
            "/update/social-link",
            selectedLink.value
          );
          if (res.status === 200) window.location.reload();
        } else {
          const res = await adminInstance.post(
            "/insert/social-link",
            selectedLink.value
          );
          if (res.status === 200) window.location.reload();
        }
      } catch (err) {
        return err;
      }
    };

    const deleteSocialLink = async (linkId) => {
      if (!window.confirm(`Холбоосыг устгах уу?`)) {
        return;
      }

      try {
        isLoading.value = true;
        const res = await adminInstance.delete(
          `/delete/social-link?id=${linkId}`
        );
        if (res.status === 200) {
          socialLinks.value = res.data.link;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    const resetForm = () => {
      selectedLink.value = {
        id: "",
        icon: "",
        link: "",
      };
    };

    return {
      isLoading,
      socialLinks,
      hotSettings,
      socialIcons,
      selectedLink,
      selectIcon,
      isIconShowing,
      openIcons,
      uploadSocialLink,
      resetForm,
    };
  },
};
</script>
