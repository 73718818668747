<template>
  <AdminNavbar>
    <h1 class="text-lg font-bold">Мэдээ нэмэх</h1>
    <NewsEditor
      :page_title="'Мэдээ нэмэх'"
      :editor_data="''"
      :button_text="'Хадгалах'"
    />
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar";
import NewsEditor from "@/components/admin/MiningMongolia/NewsEditor.vue";

export default {
  name: "MMAddNews",
  components: {
    AdminNavbar,
    NewsEditor,
  },
};
</script>
