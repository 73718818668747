<template>
  <AdminNavbar>
    <div></div>
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";

export default {
  name: "MMEventDates",
  components: {
    AdminNavbar,
  },
  setup() {
    return {};
  },
};
</script>
