<template>
  <AdminNavbar>
    <h1 class="text-lg font-bold">Мэдээ засварлах</h1>

    <!-- News List -->

    <div v-if="!editingMode" class="bg-white rounded shadow">
      <div
        class="flex justify-between items-center border-t-4 border-t-blue-500 rounded-t border-b p-3"
      >
        <h6 class="text-base">Мэдээний жагсаалт</h6>
        <router-link
          to="/admin/add-news"
          class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600"
        >
          <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />Мэдээ
          нэмэх</router-link
        >
      </div>

      <div v-if="!isLoading">
        <div class="p-3 space-y-5">
          <div class="flex justify-between items-center text-xs">
            <div class="space-x-3">
              <span>Ангилал:</span>
              <select
                name="selectedCategory"
                id="selectedCategory"
                class="border rounded p-1 text-blue-500 max-w-[20rem]"
                v-model="selectedCategory"
              >
                <option value="all">Бүгд</option>
                <option
                  v-for="cat in newsCategories"
                  :key="cat.cid"
                  :value="cat.cid"
                  class="text-pretty"
                >
                  {{ cat.category }}
                </option>
              </select>
            </div>

            <div class="space-x-3">
              <span>Хуудас бүрт:</span>
              <select
                name="pagePerPage"
                id="pagePerPage"
                class="border rounded p-1 text-blue-500"
                v-model="dataPerPage"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
            </div>

            <span>Нийт мэдээний тоо: {{ totalRequestCount }} </span>

            <div class="flex items-center gap-3">
              <span>Нийт хуудас: {{ totalPages }} </span>
              <PaginationCart
                :totalPages="totalPages"
                :modelValue="currentPage"
                :style="{
                  color: 'blue-500',
                  height: 7,
                  width: 7,
                  fontSize: 'xs',
                }"
                @page-changed="onPageChanged"
              />
            </div>
          </div>

          <div v-if="news.length > 0">
            <hot-table :settings="hotSettings" :data="news"></hot-table>
          </div>

          <div
            v-else-if="news.length === 0"
            class="flex justify-center items-center h-[60vh]"
          >
            Жагсаалт хоосон байна
          </div>
        </div>
      </div>

      <div v-else class="flex justify-center items-center h-[70vh]">
        <SpinLoading :color="'rgb(59 130 246)'" />
      </div>
    </div>

    <!-- EDITOR  -->
    <NewsEditor
      v-else
      :page_title="'Мэдээ засварлах'"
      :data="selectedNews"
      :button_text="'Шинэчлэх'"
      @cancel-edit="cancelEditHandler"
    />
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar";
import NewsEditor from "@/components/admin/MiningMongolia/NewsEditor.vue";
import PaginationCart from "@/components/ui/PaginationCart.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import { onMounted, ref, watch, computed } from "vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";
import adminInstance from "@/lib/adminInstance";
import { useStore } from "vuex";

registerAllModules();

export default {
  name: "MMAddNews",
  components: {
    AdminNavbar,
    NewsEditor,
    HotTable,
    SpinLoading,
    PaginationCart,
  },
  setup() {
    const store = useStore();
    const news = ref([]);
    const isLoading = ref(false);
    const editingMode = ref(false);
    const selectedNews = ref(null);

    const dataPerPage = ref(5);
    const totalRequestCount = ref(null);
    const totalPages = ref(1);
    const currentPage = ref(1);

    const selectedCategory = ref("all");
    const newsCategories = computed(
      () => store.getters["admin/getNewsCategory"]
    );

    const fetchNews = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get(
          `/get/news?category=${selectedCategory.value}&page=${currentPage.value}&limit=${dataPerPage.value}`
        );
        news.value = res.data.news;
        totalRequestCount.value = res.data.total;
        totalPages.value = res.data.totalPages;
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await store.dispatch("admin/fetchNewsCategory");
      await fetchNews();
    });

    watch([() => dataPerPage.value, () => selectedCategory.value], () => {
      currentPage.value = 1;
      fetchNews();
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchNews();
    };

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const hotSettings = {
      colHeaders: [
        "Үйлдэл",
        "ID",
        "Гарчиг",
        "Гарчиг /англи/",
        "Мэдээний төлөв",
        "Нийтлэгч",
        "Нийтлэсэн огноо",
        "Шинэчилсэн огноо",
      ],
      colWidths: [200, 80, 250, 250, 150, 100, 150, 150],
      columns: [
        {
          renderer: function (inst, td, row) {
            const newsId = news.value[row].id;
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-2 gap-2 text-white">
                  <button class="flex items-center bg-sky-500 py-1 px-2 rounded edit-btn">
                      <svg class="fill-current w-3 h-3 mr-1" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
                      <span>Засварлах</span>
                  </button>
                  <button class="flex justify-center items-center bg-red-500 py-1 px-2 rounded delete-btn">
                      <svg class="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
                      <span>Устгах</span>
                  </button>
              </div>`;

            const editButton = td.querySelector(".edit-btn");
            if (editButton) {
              editButton.addEventListener("click", () => {
                editNews(newsId);
              });
            }

            const deleteButton = td.querySelector(".delete-btn");
            if (deleteButton) {
              deleteButton.addEventListener("click", () => {
                deleteNews(newsId);
              });
            }
          },
        },
        {
          data: "id",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "title",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "title_en",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "news_status",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            if (value) {
              td.innerHTML = `<div class="text-green">Идэвхитэй</div>`;
            } else {
              td.innerHTML = `<div class="text-gray-500">Идэвхигүй</div>`;
            }
          },
        },
        {
          data: "publisher",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "created_at",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "updated_at",
          readOnly: true,
          renderer: commonRenderer,
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "70vh",
      fixedColumnsLeft: 1,
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const editNews = (newsId) => {
      const newsItem = news.value.find((n) => n.id === newsId);
      if (newsItem) {
        selectedNews.value = newsItem;
        editingMode.value = true;
      }
    };

    const cancelEditHandler = () => {
      editingMode.value = false;
      selectedNews.value = null;
    };

    const deleteNews = async (newsId) => {
      if (!window.confirm(`${newsId} дугаартай мэдээг устгах уу?`)) {
        return;
      }

      try {
        const res = await adminInstance.delete(`/delete/news?id=${newsId}`);
        if (res.status === 200) window.location.reload();
      } catch (err) {
        return err;
      }
    };

    return {
      news,
      isLoading,
      hotSettings,
      editingMode,
      selectedNews,
      editNews,
      cancelEditHandler,
      dataPerPage,
      totalRequestCount,
      totalPages,
      currentPage,
      onPageChanged,
      selectedCategory,
      newsCategories,
    };
  },
};
</script>
