<template>
  <AdminNavbar>
    <h1 class="text-lg font-bold">Хариуцлагатай Уул Уурхай</h1>

    <div class="bg-white rounded shadow">
      <div
        class="flex justify-between items-center border-t-4 border-t-blue-500 rounded-t border-b p-3"
      >
        <h6 class="text-base">
          {{
            isMode === "A"
              ? "Өөрийн үнэлгээ"
              : isMode === "B"
              ? "Үнэлгээний асуулт засварлах"
              : "Үнэлгээний асуулт нэмэх"
          }}
        </h6>
        <div class="space-x-3">
          <button
            v-if="isMode === 'A'"
            @click="setEditMode('B')"
            class="bg-sky-500 p-2 text-white text-xs rounded hover:bg-sky-600"
          >
            <font-awesome-icon icon="fa-solid fa-pen" class="mr-1" />
            Асуулт засах, устгах
          </button>
          <button
            v-if="isMode === 'A'"
            @click="setEditMode('C')"
            class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600"
          >
            <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />
            Асуулт нэмэх
          </button>
          <button
            v-if="isMode !== 'A'"
            @click="cancelEditHandler()"
            class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600"
          >
            <font-awesome-icon icon="fa-solid fa-angles-left" class="mr-1" />
            Буцах
          </button>
        </div>
      </div>

      <div v-if="isMode !== 'C'" class="p-3 space-y-5">
        <table
          v-for="(main, mIndex) in assessQuestions"
          :key="mIndex"
          class="w-full"
        >
          <thead>
            <tr class="bg-green text-white">
              <th class="border border-slate-300 p-2">{{ main.mainIndex }}</th>
              <th class="border border-slate-300 p-2 uppercase">
                {{ main.mainTitle }}
              </th>
              <th
                v-if="isMode === 'B'"
                class="border border-slate-300 p-2 uppercase"
              >
                Үйлдэл
              </th>
            </tr>
          </thead>

          <tbody>
            <template
              v-for="(question, qIndex) in main.questions"
              :key="qIndex"
            >
              <!-- Parent Question -->
              <tr class="text-sm bg-gray-200">
                <td class="p-2 border border-slate-300">
                  {{ question.cid }}
                </td>
                <td class="p-2 border border-slate-300">
                  {{ question.question }}
                </td>
                <td
                  v-if="isMode === 'B'"
                  class="p-2 border border-slate-300 text-white"
                >
                  <div class="flex justify-center gap-1">
                    <button
                      @click="editQuestion(question)"
                      class="bg-sky-500 hover:bg-sky-700 py-1 px-2 rounded"
                    >
                      <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                    </button>
                    <button
                      @click="deleteQuestion(question)"
                      class="bg-red-500 hover:bg-red-700 py-1 px-2 rounded"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash" />
                    </button>
                  </div>
                </td>
              </tr>

              <!-- Child Questions -->
              <tr
                v-for="(childQuestion, cIndex) in question.children"
                :key="cIndex"
                class="text-sm"
              >
                <td class="p-2 border border-slate-300">
                  {{ childQuestion.cid }}
                </td>
                <td class="p-2 border border-slate-300">
                  {{ childQuestion.question }}
                </td>
                <td
                  v-if="isMode === 'B'"
                  class="p-2 border border-slate-300 text-white"
                >
                  <div class="flex justify-center gap-1">
                    <button
                      @click="editQuestion(question)"
                      class="bg-sky-500 hover:bg-sky-700 py-1 px-2 rounded"
                    >
                      <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                    </button>
                    <button
                      @click="deleteQuestion(question)"
                      class="bg-red-500 hover:bg-red-700 py-1 px-2 rounded"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash" />
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div v-else class="p-3 space-y-5">
        <div class="flex">
          <label for="cid" class="w-[10rem]"
            >Үнэлгээний индекс<span class="text-red-500">*</span>
          </label>
          <div class="block">
            <input
              id="cid"
              name="cid"
              type="text"
              class="border rounded w-[10rem] p-1"
              :class="cidError ? 'border-red-500' : 'border-gray-300'"
              v-model="form.cid"
              @input="validateCid"
            />
            <p class="text-red-500 text-xs">{{ cidError }}</p>
          </div>
        </div>

        <div class="flex">
          <label for="question" class="w-[10rem]"
            >Үнэлгээний асуулт<span class="text-red-500">*</span>
          </label>
          <textarea
            name="question"
            id="question"
            cols="20"
            rows="5"
            class="border border-gray-300 rounded w-[60vw] p-1"
            v-model="form.question"
          ></textarea>
        </div>

        <button
          @click="uploadQuestion()"
          class="p-2 text-white"
          :class="
            form.id
              ? 'bg-sky-500 hover:bg-sky-600'
              : 'bg-[#28a745] hover:bg-[#208537]'
          "
        >
          <font-awesome-icon
            :icon="form.id ? 'fa-solid fa-pen' : 'fa-solid fa-add'"
            class="mr-1"
          />{{ form.id ? "Засах" : "Нэмэх" }}
        </button>
      </div>
    </div>
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import adminInstance from "@/lib/adminInstance";
import { onMounted, ref } from "vue";

export default {
  name: "MMUpdateAssessment",
  components: {
    AdminNavbar,
  },
  setup() {
    const isMode = ref("A");
    const isLoading = ref(false);
    const assessQuestions = ref([]);

    const form = ref({
      id: null,
      originalCid: null,
      cid: null,
      pid: null,
      question: null,
    });

    const setEditMode = (index) => {
      isMode.value = index;
    };

    const cancelEditHandler = () => {
      isMode.value = "A";
      form.value = {
        id: null,
        originalCid: null,
        cid: null,
        pid: null,
        question: null,
      };
      cidError.value = null;
    };

    const fetchAssessmentQuestions = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get("/get/assessment-questions");
        assessQuestions.value = res.data.result;
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchAssessmentQuestions();
    });

    const editQuestion = (question) => {
      if (question) {
        form.value = {
          id: question.id,
          originalCid: question.cid,
          cid: question.cid,
          pid: question.pid,
          question: question.question,
        };
        isMode.value = "C";
      }
    };

    const deleteQuestion = async (question) => {
      if (!window.confirm(`"${question.cid}" индекстэй асуултыг устгах уу?`)) {
        return;
      }

      try {
        const res = await adminInstance.delete(
          `/delete/question?id=${question.id}`
        );
        if (res.status === 200) window.location.reload();
      } catch (err) {
        return err;
      }
    };

    const cidError = ref(null);

    const validateCid = async () => {
      const pattern = /^\d+(\.\d+)+$/;
      if (form.value.cid && !pattern.test(form.value.cid)) {
        cidError.value = "Индексийн формат буруу байна. Жишээ нь: 7.4, 7.4.1";
      } else {
        if (form.value.originalCid === form.value.cid) {
          return (cidError.value = null);
        }

        try {
          const res = await adminInstance.get(
            `/check/index?cid=${form.value.cid}`
          );

          if (res.status === 200 && res.data.result.length !== 0) {
            cidError.value = "Индекс давхцаж байна";
          } else {
            cidError.value = null;
          }
        } catch (err) {
          return err;
        }
      }
    };

    const isValid = () => {
      if (!form.value.cid) return alert("Үнэлгээний индексийг оруулна уу");
      if (!form.value.question) return alert("Үнэлгээний асуултыг оруулна уу");

      return true;
    };

    const setPid = () => {
      const cid = form.value.cid;

      if (!cid) return;

      const parts = cid.split(".");
      if (parts.length <= 2) {
        form.value.pid = null;
      } else {
        form.value.pid = parts.slice(0, -1).join(".");
      }
    };

    const uploadQuestion = async () => {
      if (!isValid()) return;

      if (cidError.value) return alert("Индексийн формат алдаатай байна.");

      try {
        if (form.value.id) {
          const res = await adminInstance.post("/update/question", form.value);
          if (res.status === 200) window.location.reload();
        } else {
          setPid();
          const res = await adminInstance.post("/insert/question", form.value);
          if (res.status === 200) window.location.reload();
        }
      } catch (err) {
        return err;
      }
    };

    return {
      isMode,
      isLoading,
      assessQuestions,
      form,
      setEditMode,
      cancelEditHandler,
      uploadQuestion,
      editQuestion,
      deleteQuestion,
      validateCid,
      cidError,
    };
  },
};
</script>
