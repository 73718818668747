<template>
  <AdminNavbar>
    <h1 class="text-lg font-bold">Галлериa</h1>

    <div class="bg-white rounded shadow">
      <h6 class="text-base border-t-4 border-t-blue-500 rounded-t border-b p-3">
        Фото зургууд
      </h6>

      <div v-if="!isLoading" class="p-3 space-y-5">
        <div class="flex items-center w-full gap-2 p-3">
          <label for="event_date_id" class="font-bold w-1/5"
            >Хөтөлбөрийн огноо<span class="text-red-500">*</span></label
          >
          <select
            name="event_date_id"
            id="event_date_id"
            class="border rounded p-2 w-4/5"
            :class="
              isSubmitted && !form.event_date_id
                ? 'border-red-500'
                : 'border-gray-300'
            "
            v-model="form.event_date_id"
          >
            <option v-for="date in eventDates" :key="date.id" :value="date.id">
              {{ `${date.event} - ${date.day} - ${date.event_date}` }}
            </option>
          </select>
        </div>

        <div class="flex flex-col">
          <input
            type="file"
            class="border rounded p-2"
            multiple
            @change="onFilesChange"
          />
          <span class="text-xs text-blue-500 mt-1"
            >Олон файл нэг дор сонгож оруулах боломжтой.</span
          >
        </div>

        <div>
          <button
            @click="uploadImages"
            class="bg-blue-500 text-white text-xs px-4 py-2 rounded"
          >
            <font-awesome-icon icon="fa-solid fa-plus" class="mr-1" />
            Зураг оруулах
          </button>
        </div>

        <h1 class="text-center font-bold">Оруулсан зургийн жагсаалт</h1>
        <div v-if="fileNames.length">
          <ul>
            <li v-for="(fileName, index) in fileNames" :key="index">
              {{ index + 1 }}. {{ fileName }}
            </li>
          </ul>
        </div>

        <div
          v-else
          class="flex justify-center items-center h-[30vh] text-gray-500 text-xs"
        >
          Жагсаалт хоосон байна.
        </div>
      </div>

      <div v-else class="flex justify-center items-center h-[70vh]">
        <SpinLoading />
      </div>
    </div>
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import { onMounted, ref } from "vue";
import adminMWInstance from "@/lib/adminMWInstance";

export default {
  name: "MWGallery",
  components: {
    AdminNavbar,
    SpinLoading,
  },
  setup() {
    const eventDates = ref([]);
    const form = ref({
      event_date_id: null,
    });

    const fetchAgendaDates = async () => {
      try {
        const res = await adminMWInstance.get(
          `/get/event-dates?language=${"all"}`
        );
        if (res.status === 200) {
          eventDates.value = res.data.dates;
        }
      } catch (err) {
        return err;
      }
    };

    onMounted(() => {
      fetchAgendaDates();
    });

    const isLoading = ref(false);
    const selectedFiles = ref([]);
    const fileNames = ref([]);

    const onFilesChange = (event) => {
      selectedFiles.value = event.target.files;
      fileNames.value = Array.from(selectedFiles.value).map(
        (file) => file.name
      );
    };

    const uploadImages = async () => {
      if (selectedFiles.value.length) {
        const formData = new FormData();
        for (let i = 0; i < selectedFiles.value.length; i++) {
          formData.append("images", selectedFiles.value[i]);
        }

        try {
          isLoading.value = true;
          const response = await adminMWInstance.post(
            "/insert/gallery",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status === 200) {
            selectedFiles.value = [];
            fileNames.value = [];
            alert("Амжилттай нэмэгдлээ.");
          }
        } catch (error) {
          console.error("Upload failed:", error);
          alert(`Алдаа гарлаа: ${error}`);
        } finally {
          isLoading.value = false;
        }
      }
    };

    return {
      eventDates,
      fileNames,
      onFilesChange,
      uploadImages,
      isLoading,
      form,
    };
  },
};
</script>
