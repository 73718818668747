<template>
  <AdminNavbar>
    <h1 class="text-lg font-bold">Админы эрхтэй хэрэглэгчид</h1>

    <div class="bg-white rounded shadow">
      <div
        class="flex justify-between items-center border-t-4 border-t-blue-500 rounded-t border-b p-3"
      >
        <h6 class="text-base">
          {{
            !isEditingMode
              ? "Админ"
              : form.id
              ? "Хэрэглэгч засварлах"
              : "Хэрэглэгч нэмэх"
          }}
        </h6>
        <button
          v-if="!isEditingMode"
          @click="setEditMode()"
          class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600"
        >
          <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />
          Хэрэглэгч нэмэх
        </button>
        <button
          v-if="isEditingMode"
          @click="cancelEditHandler()"
          class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600"
        >
          <font-awesome-icon icon="fa-solid fa-angles-left" class="mr-1" />
          Буцах
        </button>
      </div>

      <!-- list -->
      <div v-if="!isEditingMode" class="p-3 space-y-5">
        <div v-if="adminAccounts.length > 0 && !isLoading" class="space-y-5">
          <div class="flex justify-between items-center text-xs">
            <div class="space-x-3">
              <span>Хуудас бүрт:</span>
              <select
                name="dataPerPage"
                id="dataPerPage"
                class="border rounded p-1 text-blue-500"
                v-model="dataPerPage"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
            </div>

            <span>Нийт админы тоо: {{ totalAdminCount }} </span>

            <div class="flex items-center gap-3">
              <span>Нийт хуудас: {{ totalPages }} </span>
              <PaginationCart
                :totalPages="totalPages"
                :modelValue="currentPage"
                :style="{
                  color: 'blue-500',
                  height: 7,
                  width: 7,
                  fontSize: 'xs',
                }"
                @page-changed="onPageChanged"
              />
            </div>
          </div>
          <hot-table :settings="hotSettings" :data="adminAccounts"></hot-table>
        </div>

        <div
          v-else-if="adminAccounts.length === 0 && !isLoading"
          class="flex justify-center items-center h-[40vh]"
        >
          Жагсаалт хоосон байна
        </div>

        <div v-else class="flex justify-center items-center h-[40vh]">
          <SpinLoading :color="'rgb(59 130 246)'" />
        </div>
      </div>

      <!-- edit account -->
      <div v-else class="flex flex-col items-center p-3 w-full">
        <div class="space-y-3 w-[30vw]">
          <div class="flex justify-center w-full">
            <div
              class="flex justify-center items-center h-24 w-24 rounded-full shadow p-2 my-7"
            >
              <span class="text-4xl font-bold text-green">
                {{ form.first_name ? form.first_name.charAt(0) : "" }}
              </span>
            </div>
          </div>

          <div class="flex gap-5 w-full">
            <span class="space-y-1">
              <label for="last_name" class="">Овог</label>
              <input
                id="last_name"
                name="last_name"
                type="text"
                class="w-full bg-slate-50 border border-gray-300 rounded py-1 px-3"
                v-model="form.last_name"
              />
            </span>
            <span class="space-y-1">
              <label for="first_name"
                >Нэр<span class="text-red-500">*</span></label
              >
              <input
                id="first_name"
                name="first_name"
                type="text"
                class="w-full bg-slate-50 border border-gray-300 rounded py-1 px-3"
                v-model="form.first_name"
              />
            </span>
          </div>

          <div class="space-y-1">
            <label for="email"
              >И-мэйл хаяг<span class="text-red-500">*</span></label
            >
            <input
              id="email"
              name="email"
              type="email"
              class="w-full bg-slate-50 border border-gray-300 rounded py-1 px-3"
              v-model="form.email"
            />
          </div>

          <div class="space-y-1">
            <label for="phone_number"
              >Утасны дугаар<span class="text-red-500">*</span></label
            >
            <input
              id="phone_number"
              name="phone_number"
              type="tel"
              class="w-full bg-slate-50 border border-gray-300 rounded py-1 px-3"
              v-model="form.phone_number"
            />
          </div>

          <div class="flex gap-5 w-full">
            <span class="space-y-1">
              <label for="position" class="">Албан тушаал</label>
              <input
                id="position"
                name="position"
                type="text"
                class="w-full bg-slate-50 border border-gray-300 rounded py-1 px-3"
                v-model="form.position"
              />
            </span>
            <span class="space-y-1">
              <label for="department">Хэлтэс</label>
              <input
                id="department"
                name="department"
                type="text"
                class="w-full bg-slate-50 border border-gray-300 rounded py-1 px-3"
                v-model="form.department"
              />
            </span>
          </div>

          <div class="space-y-1">
            <label for="adminAuth"
              >Хэрэглэгчийн эрхийн төрөл<span class="text-red-500"
                >*</span
              ></label
            >
            <select
              name="adminAuth"
              id="adminAuth"
              class="w-full bg-slate-50 border border-gray-300 rounded py-1 px-3"
              v-model="form.account_role"
            >
              <option value="1">Модератор</option>
              <option value="2">Админ</option>
              <option value="3">Хэрэглэгч</option>
            </select>
          </div>

          <button
            @click="uploadAccount()"
            class="p-2 text-white"
            :class="
              form.id
                ? 'bg-sky-500 hover:bg-sky-600'
                : 'bg-[#28a745] hover:bg-[#208537]'
            "
          >
            <font-awesome-icon
              :icon="form.id ? 'fa-solid fa-pen' : 'fa-solid fa-add'"
              class="mr-1"
            />{{ form.id ? "Засварлах" : "Нэмэх" }}
          </button>
        </div>
      </div>
    </div>
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import { onMounted, ref, watch } from "vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";
import adminInstance from "@/lib/adminInstance";
import PaginationCart from "@/components/ui/PaginationCart.vue";

registerAllModules();

export default {
  name: "MMAccounts",
  components: {
    AdminNavbar,
    SpinLoading,
    HotTable,
    PaginationCart,
  },
  setup() {
    const isEditingMode = ref(false);
    const isLoading = ref(false);
    const dataPerPage = ref(10);
    const totalAdminCount = ref(0);
    const totalPages = ref(1);
    const currentPage = ref(1);
    const adminAccounts = ref([]);

    const form = ref({
      id: null,
      last_name: null,
      first_name: null,
      email: null,
      phone_number: null,
      position: null,
      department: null,
      account_role: 2,
    });

    const fetchAdminAccounts = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get(
          `/get/admin-users?page=${currentPage.value}&limit=${dataPerPage.value}`
        );
        adminAccounts.value = res.data.account;
        totalPages.value = res.data.totalPages;
        totalAdminCount.value = res.data.total;
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchAdminAccounts();
    });

    watch([() => dataPerPage.value], () => {
      currentPage.value = 1;
      fetchAdminAccounts();
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchAdminAccounts();
    };

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center w-full p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const roleRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      let displayValue;
      switch (value) {
        case 1:
          displayValue = "Модератор";
          break;
        case 2:
          displayValue = "Админ";
          break;
        case 3:
          displayValue = "Хэрэглэгч";
          break;
        default:
          displayValue = "";
      }
      td.innerHTML = `<span class="flex justify-center w-full p-2 text-black">${displayValue}</span>`;
      return td;
    };

    const hotSettings = {
      colHeaders: [
        "Үйлдэл",
        "Эрхийн төрөл",
        "Овог",
        "Нэр",
        "И-мэйл хаяг",
        "Утасны дугаар",
        "Албан тушаал",
        "Хэлтэс",
      ],
      columns: [
        {
          renderer: function (inst, td, row) {
            const account = adminAccounts.value[row];
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-2 gap-2 text-white">
                  <button class="flex items-center bg-sky-500 py-1 px-2 rounded edit-btn">
                      <svg class="fill-current w-3 h-3 mr-1" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
                      <span>Засварлах</span>
                  </button>
                  <button class="flex justify-center items-center bg-red-500 py-1 px-2 rounded delete-btn">
                      <svg class="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
                      <span>Устгах</span>
                  </button>
              </div>`;

            const editButton = td.querySelector(".edit-btn");
            if (editButton) {
              editButton.addEventListener("click", () => {
                editAccount(account);
              });
            }

            const deleteButton = td.querySelector(".delete-btn");
            if (deleteButton) {
              deleteButton.addEventListener("click", () => {
                deleteAccount(account.id, account.email);
              });
            }
          },
        },
        {
          data: "account_role",
          readOnly: true,
          renderer: roleRenderer,
        },
        {
          data: "last_name",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "first_name",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "email",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "phone_number",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "position",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "department",
          readOnly: true,
          renderer: commonRenderer,
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "60vh",
      fixedColumnsLeft: 1,
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const editAccount = (account) => {
      if (account) {
        form.value = account;
        isEditingMode.value = true;
      }
    };

    const setEditMode = () => {
      isEditingMode.value = true;
    };

    const cancelEditHandler = () => {
      isEditingMode.value = false;
      form.value = {
        id: null,
        last_name: null,
        first_name: null,
        email: null,
        phone_number: null,
        position: null,
        department: null,
        account_role: 2,
      };
    };

    const deleteAccount = async (id, email) => {
      if (
        !window.confirm(`"${email}" и-мэйл хаягтай хэрэглэгчийг устгах уу?`)
      ) {
        return;
      }

      try {
        const res = await adminInstance.delete(`/delete/account?id=${id}`);
        if (res.status === 200) window.location.reload();
      } catch (err) {
        return err;
      }
    };

    const isValid = () => {
      if (!form.value.first_name)
        return alert("Хэрэглэгчийн нэрийг оруулна уу");
      if (!form.value.email)
        return alert("Хэрэглэгчийн и-мэйл хаягийг оруулна уу");
      if (!form.value.account_role)
        return alert("Хэрэглэгчийн эрхийн төрлийг сонгоно уу");

      return true;
    };

    const uploadAccount = async () => {
      if (!isValid()) return;

      try {
        if (form.value.id) {
          const res = await adminInstance.post("/update/account", form.value);
          if (res.status === 200) window.location.reload();
        } else {
          const res = await adminInstance.post("/insert/account", form.value);
          if (res.status === 200) window.location.reload();
        }
      } catch (err) {
        return err;
      }
    };

    return {
      isEditingMode,
      isLoading,
      adminAccounts,
      form,
      hotSettings,
      dataPerPage,
      totalAdminCount,
      totalPages,
      onPageChanged,
      currentPage,
      cancelEditHandler,
      uploadAccount,
      setEditMode,
    };
  },
};
</script>
