<template>
  <button
    @click="scrollToTop"
    :class="showButton ? `block ${backgroundColor}` : 'hidden'"
    class="fixed bottom-8 left-8 text-white text-lg rounded-full px-3 py-1 z-20 shadow-[2px_2px_7px_0px_#1a1b1d4b]"
  >
    <font-awesome-icon icon="fa-solid fa-arrow-up" />
  </button>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";

export default {
  name: "BackToTheTopButton",
  props: {
    backgroundColor: {
      type: String,
      default: "bg-green",
    },
  },
  setup() {
    const showButton = ref(false);

    const handleScroll = () => {
      showButton.value = window.scrollY > 200;
    };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return { showButton, scrollToTop };
  },
};
</script>
