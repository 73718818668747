<template>
  <AdminNavbar>
    <div v-if="!isLoading" class="grid grid-cols-4 justify-between gap-5">
      <div class="flex justify-between bg-white rounded-lg shadow-lg p-5">
        <span class="flex flex-col justify-center gap-1">
          <h6 class="text-xs text-gray-500">Энэ сарын хандалт</h6>
          <p class="text-blue-500 font-bold text-2xl">-</p>
        </span>
        <div
          class="bg-blue-50 h-12 w-12 rounded-full flex items-center justify-center"
        >
          <font-awesome-icon
            icon="fa-solid fa-earth-asia"
            class="text-blue-500 text-xl"
          />
        </div>
      </div>

      <router-link
        to="/admin/request-list"
        class="flex justify-between bg-white rounded-lg shadow-lg p-5"
      >
        <span class="flex flex-col justify-center gap-1">
          <h6 class="text-xs text-gray-500">Энэ сарын хүсэлт</h6>
          <p class="text-indigo-500 font-bold text-2xl">
            {{ totalOfOfficeRequests ? totalOfOfficeRequests : 0 }}
          </p>
        </span>
        <div
          class="bg-indigo-100 h-12 w-12 rounded-full flex items-center justify-center"
        >
          <font-awesome-icon
            icon="fa-regular fa-handshake"
            class="text-indigo-500 text-xl"
          />
        </div>
      </router-link>

      <router-link
        to="/admin/update-news"
        class="flex justify-between bg-white rounded-lg shadow-lg p-5"
      >
        <span class="flex flex-col justify-center gap-1">
          <h6 class="text-xs text-gray-500">Нийт мэдээ, мэдээлэл</h6>
          <p class="text-green font-bold text-2xl">
            {{ totalOfNews ? totalOfNews : 0 }}
          </p>
        </span>
        <div
          class="bg-[#edf2e9] h-12 w-12 rounded-full flex items-center justify-center"
        >
          <font-awesome-icon
            icon="fa-regular fa-newspaper"
            class="text-green text-xl"
          />
        </div>
      </router-link>
    </div>

    <div v-else class="flex justify-center items-center h-[80vh]">
      <SpinLoading :color="'rgb(59 130 246)'" />
    </div>
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import { onMounted, ref } from "vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import adminInstance from "@/lib/adminInstance";

export default {
  name: "MMDashboard",
  components: {
    AdminNavbar,
    SpinLoading,
  },
  setup() {
    const isLoading = ref(false);
    const totalOfNews = ref(0);
    const totalOfOfficeRequests = ref(0);

    const fetchCounts = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get("/get/dashboard");

        if (res.data.result) {
          totalOfNews.value = res.data.result.newsCount || 0;
          totalOfOfficeRequests.value = res.data.result.requestCount || 0;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchCounts();
    });

    return { totalOfNews, totalOfOfficeRequests, isLoading };
  },
};
</script>
