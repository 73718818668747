<template>
  <AdminNavbar>
    <h1 class="font-bold text-lg">Цэс</h1>
    <div class="rounded bg-white">
      <div class="border-b p-3 border-t-4 border-t-blue-500 rounded-t">
        <h6 class="text-base">Mining Week</h6>
      </div>

      <div class="flex gap-10 p-3">
        <!-- MENU LIST -->
        <div class="rounded shadow h-fit w-1/3">
          <h6 class="border-b p-3">Цэсний жагсаалт</h6>
          <ul v-if="!isLoading && categories.length > 0" class="p-3 space-y-3">
            <li v-for="(cat, index) in categories" :key="index">
              <div class="flex items-center justify-between uppercase">
                <div class="flex items-center gap-2">
                  <font-awesome-icon
                    icon="fa-solid fa-circle"
                    class="text-xs"
                    :class="
                      cat.category_status === true
                        ? 'text-[#28a745]'
                        : 'text-red-500'
                    "
                  />
                  <h1 class="min-w-[10rem] text-nowrap">
                    {{ cat.category }}
                  </h1>
                </div>
                <div class="text-white text-xs">
                  <button
                    @click="editMenu(cat, cat.cid)"
                    class="bg-sky-500 hover:bg-sky-700 p-2"
                  >
                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                  </button>
                  <button
                    @click="deleteMenu(cat.category, cat.cid)"
                    class="bg-red-500 hover:bg-red-700 p-2"
                  >
                    <font-awesome-icon icon="fa-solid fa-trash" />
                  </button>
                </div>
              </div>
            </li>
          </ul>

          <div
            v-else-if="categories.length === 0 && !isLoading"
            class="flex justify-center items-center h-[60vh]"
          >
            Цэс хоосон байна
          </div>

          <div v-else class="flex justify-center items-center h-[60vh]">
            <SpinLoading :color="'rgb(59 130 246)'" />
          </div>
        </div>

        <!-- ADD MENU -->
        <div class="rounded shadow w-[25rem] h-fit">
          <h6 class="border-b p-3">Нэмэх / Засварлах</h6>

          <div class="flex flex-col gap-2 p-3 w-full">
            <div class="flex flex-col gap-2">
              <label for="category" class="font-bold">Цэсний нэр</label>
              <input
                type="text"
                name="category"
                id="category"
                placeholder="Ангилал"
                class="border rounded p-2"
                v-model="form.category"
              />

              <label for="category_en" class="font-bold"
                >Цэсний нэр /англи/</label
              >
              <input
                type="text"
                name="category_en"
                id="category_en"
                placeholder="Category"
                class="border rounded p-2"
                v-model="form.category_en"
              />

              <label for="category_status" class="font-bold"
                >Цэсний төлөв</label
              >
              <select
                name="category_status"
                id="category_status"
                class="border rounded p-2"
                v-model="form.category_status"
              >
                <option value="true">Идэвхитэй</option>
                <option value="false">Идэвхигүй</option>
              </select>
            </div>
          </div>

          <div class="flex bg-gray-200 gap-5 p-3 text-xs text-white">
            <button
              @click="uploadMenu()"
              class="p-2"
              :class="
                form.cid
                  ? 'bg-sky-500 hover:bg-sky-600'
                  : 'bg-[#28a745] hover:bg-[#208537]'
              "
            >
              <font-awesome-icon
                :icon="form.cid ? 'fa-solid fa-pen' : 'fa-solid fa-add'"
                class="mr-1"
              />{{ form.cid ? "Линк засварлах" : "Линк нэмэх" }}
            </button>

            <button
              @click="resetForm()"
              class="p-2 bg-orange-400 hover:bg-orange-500"
            >
              <font-awesome-icon
                icon="fa-solid fa-eraser"
                class="mr-1"
              />Арилгах
            </button>
          </div>
        </div>
      </div>
    </div>
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import adminMWInstance from "@/lib/adminMWInstance";
import { onMounted, ref } from "vue";

export default {
  name: "MWWebCategories",
  components: { AdminNavbar, SpinLoading },
  setup() {
    const isLoading = ref(false);
    const categories = ref([]);

    const fetchAllCategories = async () => {
      try {
        isLoading.value = true;
        const res = await adminMWInstance.get("/get/categories");
        if (res.status === 200) {
          categories.value = res.data.category;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchAllCategories();
    });

    const editMenu = (menu) => {
      form.value = {
        cid: menu.cid,
        category: menu.category,
        category_en: menu.category_en,
        pid: menu.pid,
        category_status: menu.category_status,
        page_type: menu.page_type,
      };
    };

    const deleteMenu = async (name, cid) => {
      if (!window.confirm(`'${name}' цэсийг устгах уу?`)) {
        return;
      }

      try {
        const res = await adminMWInstance.delete(`/delete/category?cid=${cid}`);
        if (res.status === 200) window.location.reload();
      } catch (err) {
        return err;
      }
    };

    //Нэмэх, засах, шинэчлэх
    const form = ref({
      cid: null,
      category: null,
      category_en: null,
      pid: null,
      category_status: true,
      page_type: "static",
    });

    const resetForm = ref({
      cid: null,
      category: null,
      category_en: null,
      pid: null,
      category_status: true,
      page_type: "static",
    });

    const isValid = () => {
      if (!form.value.category) return alert("Цэсний нэрийг оруулна уу");
      if (!form.value.category_en)
        return alert("Цэсний англи нэрийг оруулна уу");

      return true;
    };

    const uploadMenu = async () => {
      if (!isValid()) return;

      try {
        if (!form.value.cid) {
          const res = await adminMWInstance.post(
            "/insert/category",
            form.value
          );
          if (res.status === 200) window.location.reload();
        } else {
          const res = await adminMWInstance.post(
            "/update/category",
            form.value
          );
          if (res.status === 200) window.location.reload();
        }
      } catch (err) {
        return err;
      }
    };

    return {
      isLoading,
      categories,
      form,
      editMenu,
      deleteMenu,
      uploadMenu,
      resetForm,
    };
  },
};
</script>
