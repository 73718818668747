<template>
  <div class="flex justify-center items-center h-screen bg-gray-200">
    <div
      class="flex flex-col justify-center items-center bg-white space-y-4 p-3 shadow w-[20rem]"
    >
      <h1 class="text-xl text-center font-bold text-green">Админ</h1>
      <p v-if="error" class="text-center text-red-500 text-xs">
        {{ error }}
      </p>
      <div id="buttonDiv" class="my-10"></div>
      <p class="text-center text-gray-500 text-xs mt-10">
        &copy; All rights reserved.
      </p>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "AdminLogin",
  setup() {
    const store = useStore();
    const error = ref("");
    const router = useRouter();

    const handleCredentialResponse = async (response) => {
      try {
        const res = await store.dispatch("admin/fetchAdminInformation", {
          idToken: response.credential,
        });
        if (res && res.status === 200) {
          router.push("/admin/dashboard");
        }
      } catch (e) {
        error.value = e.response.data.message;
      }
    };

    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id:
          "156801078246-rih4ur764sl7v3oqfqudomh1omkosv0s.apps.googleusercontent.com",
        callback: handleCredentialResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "large" }
      );
      window.google.accounts.id.prompt();
    };

    onMounted(() => {
      initializeGoogleSignIn();
    });

    return {
      handleCredentialResponse,
      initializeGoogleSignIn,
      error,
    };
  },
};
</script>
