<template>
  <MW2023Background>
    <header class="relative w-full">
      <img
        src="../../../assets/images/miningWeek/news/news-header.jpg"
        alt="header-image"
        class="w-full h-[50vh] object-cover object-center"
      />
      <div
        class="absolute inset-0 flex flex-col justify-center items-center gap-2 text-white"
      >
        <h1 class="uppercase text-xl font-bold">
          {{ lang === "mn" ? "Дэлгэрэнгүй мэдээлэл" : "" }}
        </h1>
        <div class="flex divide-x divide-gray-400 text-gray-400">
          <router-link to="/mw/2023" class="px-2 hover:text-white">
            {{ lang === "mn" ? "Нүүр" : "Home" }}
          </router-link>
          <router-link to="/mw/2023/news" class="px-2 hover:text-white">
            {{ lang === "mn" ? "Мэдээ мэдээлэл" : "News" }}
          </router-link>
        </div>
      </div>
    </header>

    <main class="relative flex justify-center w-full py-10 text-white">
      <div class="flex w-full min-[1200px]:w-4/5 max-[1199px]:px-3 gap-10">
        <section class="w-3/4">hello</section>
        <section class="w-1/4">
          <MW2023NewsSide />
        </section>
      </div>
    </main>
  </MW2023Background>
</template>

<script>
import MW2023Background from "@/components/MiningWeek/MW2023/MW2023Background.vue";
import MW2023NewsSide from "@/containers/MiningWeek/MW2023NewsSide.vue";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "MW2023NewsList",
  components: {
    MW2023Background,
    MW2023NewsSide,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    return { lang };
  },
};
</script>
