<template>
  <div class="flex items-center gap-5 text-sm">
    <button
      @click="prevPage"
      :disabled="currentPage === 1"
      class="flex items-center border border-gray-100 text-blue-500 p-3 text-sm hover:enabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
    >
      <font-awesome-icon icon="fa-solid fa-angle-left" class="mr-2" />
      Өмнөх
    </button>

    <button
      @click="requestNextPage"
      :disabled="currentPage === totalPages"
      class="flex items-center border border-gray-100 text-blue-500 p-3 text-sm hover:enabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
    >
      Дараах
      <font-awesome-icon icon="fa-solid fa-angle-right" class="ml-2" />
    </button>
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "PaginationCartTwo",
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    modelValue: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const currentPage = ref(props.modelValue);

    watch(
      () => props.modelValue,
      (newVal) => {
        currentPage.value = newVal;
      }
    );

    const requestNextPage = () => {
      if (currentPage.value < props.totalPages) {
        emit("request-next-page", currentPage.value + 1);
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        emit("page-changed", currentPage.value - 1);
      }
    };

    return {
      currentPage,
      prevPage,
      requestNextPage,
    };
  },
};
</script>
