<template>
  <div></div>
</template>

<script>
export default {
  name: "MWUpdateNews",
  setup() {
    return {};
  },
};
</script>
