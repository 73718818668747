<template>
  <footer
    class="flex flex-col justify-center items-center bg-green text-white tracking-wide leading-5"
    :class="lang === 'mn' ? 'text-sm' : 'text-xs'"
  >
    <div
      v-motion-slide-visible-once-left
      :duration="1000"
      class="flex max-lg:flex-col gap-10 w-full min-[1200px]:w-4/5 max-[1199px]:p-5 py-5"
    >
      <!-- 1st column -->
      <div class="w-full lg:w-1/4">
        <p class="mb-4">
          {{
            lang === "mn"
              ? "Монгол Улс, Улаанбаатар хот, СБД, 1-р хороо, Элчингийн гудамж, Скай Плаза бизнес төв, Д корпус 2 давхар"
              : "2nd floor, D section, “Sky Plaza” Business Center, Embassy Rd, Sukhbaatar district, Ulaanbaatar, Mongolia 14220"
          }}
        </p>
        <p class="mb-4">+976 70110163</p>
        <p class="mb-14">info@miningmongolia.mn</p>
        <div class="flex items-center gap-5 text-lg">
          <a
            v-for="link in socialLinks"
            :key="link.id"
            :href="link.link"
            target="_blank"
          >
            <font-awesome-icon :icon="link.icon" />
          </a>
        </div>
      </div>

      <!-- Rest columns -->
      <ul
        class="w-full lg:w-3/4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-5 gap-x-10"
      >
        <li v-for="parent in mainCategories" :key="parent.cid">
          <router-link
            :to="getCategoryLink(parent)"
            class="font-bold text-base hover:underline"
          >
            {{ lang === "mn" ? parent.category : parent.category_en }}
          </router-link>

          <ul class="mt-1">
            <li
              v-for="child in childCategories.filter(
                (child) => child.pid === parent.cid
              )"
              :key="child.cid"
              class="font-light hover:underline"
            >
              <router-link
                :to="getChildCategoryLink(parent, child)"
                class="block"
              >
                {{ lang === "mn" ? child.category : child.category_en }}
              </router-link>
            </li>
          </ul>
        </li>

        <li v-for="parent in subCategories" :key="parent.cid">
          <router-link
            :to="`/mm/category/${parent.pid}#${parent.category}`"
            class="font-bold text-base hover:underline"
          >
            {{ lang === "mn" ? parent.category : parent.category_en }}
          </router-link>

          <ul class="mt-1">
            <li
              v-for="child in grandChildCategories.filter(
                (child) => child.pid === parent.cid
              )"
              :key="child.cid"
              class="font-light hover:underline"
            >
              <router-link :to="`/mm/page/${child.cid}`" class="block">
                {{ lang === "mn" ? child.category : child.category_en }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div
      v-motion-slide-visible-once-bottom
      :duration="1000"
      class="flex justify-start items-center w-full min-[1200px]:w-4/5 max-[1199px]:p-5 mb-4 text-xs"
    >
      <a href="https://bowsys.mn" target="_self" class="hover:underline"
        >&copy; Developed By BOW LLC</a
      >
    </div>
  </footer>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import clientInstance from "@/lib/clientInstance";

export default {
  name: "MMFooter",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const socialLinks = computed(() => store.getters["client/getSocialLinks"]);
    const mainCategories = ref([]);
    const childCategories = ref([]);
    const subCategories = ref([]);
    const grandChildCategories = ref([]);

    onMounted(async () => {
      try {
        await store.dispatch("client/fetchSocialLinks");
        const res = await clientInstance.get("/get/footer-category");
        mainCategories.value = res.data.mainCategories;
        childCategories.value = res.data.childCategories;
        subCategories.value = res.data.subCategories;
        grandChildCategories.value = res.data.grandChildCategories;
      } catch (err) {
        return err;
      }
    });

    const getCategoryLink = (category) => {
      if (category.page_type === "static") {
        return `/mm/category/${category.cid}`;
      } else if (category.page_type === "news") {
        return `/mm/blog/${category.cid}=${1}`;
      } else {
        return `/mm/category/${category.cid}`;
      }
    };

    const getChildCategoryLink = (parentCategory, childCategory) => {
      if (parentCategory.page_type === "static") {
        return `/mm/category/${parentCategory.cid}#${childCategory.category}`;
      } else if (parentCategory.page_type === "news") {
        return `/mm/blog/${childCategory.cid}=${1}`;
      } else {
        return `/mm/category/${parentCategory.cid}#${childCategory.category}`;
      }
    };

    return {
      lang,
      socialLinks,
      mainCategories,
      childCategories,
      subCategories,
      grandChildCategories,
      getCategoryLink,
      getChildCategoryLink,
    };
  },
};
</script>
