<template>
  <div
    v-for="(project, index) in projects"
    :key="index"
    v-motion-slide-visible-once-left
    :duration="1000"
    class="rounded px-5 py-14 shadow-[0px_10px_29.7px_0.3px_rgba(225,225,225,0.64)]"
  >
    <img
      :src="lang === 'mn' ? project.image : project.image_en"
      :alt="index"
      class="h-[3rem] object-contain object-center"
    />
    <span
      class="line-clamp-3 font-light text-sm leading-6 tracking-wider my-5"
      >{{ lang === "mn" ? project.text : project.text_en }}</span
    >
    <button
      @click="goToPage(project.to)"
      class="text-white py-2 px-6 rounded-full hover:before:bg-lightGreen relative overflow-hidden bg-green transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-lightGreen before:transition-all before:duration-500 hover:before:left-0 hover:before:w-full"
    >
      <span class="relative z-10">
        {{ lang === "mn" ? "Дэлгэрэнгүй" : "Read more"
        }}<font-awesome-icon
          icon="fa-solid fa-arrow-right-long"
          class="ml-2 text-xs"
      /></span>
    </button>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "MMProjects",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const projects = [
      {
        image: require("../../assets/images/miningMongolia/home-pages-cards/hariutslagatai uul uurhai logo.png"),
        text: "Уурхайг ашигласнаар улс орны хөгжилд нь нэмэртэй, ард түмний амьдралд тустай, байгаль орчин, хүний эрүүл мэндэд хохиролгүй байхыг хэлэх ба энэ нь уул уурхайг төлөвлөж эхлэхээс авахуулаад дуусах хүртэл хийгдэх олон талын цогц арга хэмжээ юм. ",
        image_en: require("../../assets/images/miningMongolia/home-pages-cards/hariutslagatai uul uurhai logo en.png"),
        text_en:
          "The utilization of mines should contribute to the country's development, enrich people's lives, while ensuring the preservation of the environment and human health. The Mongolian National Mining Association has launched a responsible mining initiative in Mongolia since 2006 to `actively participate in the development of responsible mountains in Mongolia's mineral industry` and working to implement this principle.",
        to: "/mm/page/20",
      },
      {
        image: require("../../assets/images/miningMongolia/home-pages-cards/bidnii medehgui uul uurhai logo-01.png"),
        text: "Монголын уул уурхайн компаниудын нийгмийн хариуцлагаа дээшлүүлэх, тэдгээрийн тогтвортой хөгжлийг хангах зорилгоор",
        image_en: require("../../assets/images/miningMongolia/home-pages-cards/bidnii medehgui uul uurhai logo-02.png"),
        text_en:
          "The utilization of mines should contribute to the country's development, enrich people's lives, while ensuring the preservation of the environment and human health. The Mongolian National Mining Association has launched a responsible mining initiative in Mongolia since 2006 to `actively participate in the development of responsible mountains in Mongolia's mineral industry` and working to implement this principle.",
        to: "/mm/page/21",
      },
      {
        image: require("../../assets/images/miningMongolia/home-pages-cards/mining 101 logo-02.png"),
        text: "Монголын уул уурхайн компаниудын нийгмийн хариуцлагаа дээшлүүлэх, тэдгээрийн тогтвортой хөгжлийг хангах зорилгоор",
        image_en: require("../../assets/images/miningMongolia/home-pages-cards/mining 101 logo-01.png"),
        text_en:
          "The utilization of mines should contribute to the country's development, enrich people's lives, while ensuring the preservation of the environment and human health. The Mongolian National Mining Association has launched a responsible mining initiative in Mongolia since 2006 to `actively participate in the development of responsible mountains in Mongolia's mineral industry` and working to implement this principle.",
        to: "/mm/page/22",
      },
      {
        image: require("../../assets/images/miningMongolia/home-pages-cards/page-4.png"),
        text: "“Монгол улсын эрдэс баялгийн салбарын боловсон хүчний хэрэгцээ шаардлага болон боломжит олон улсын тэтгэлгийн танилцуулга” цуврал уулзалт.",
        image_en: require("../../assets/images/miningMongolia/home-pages-cards/page-4.png"),
        text_en:
          "`Introduction of the personnel needs of Mongolia`s mineral resources sector and possible international scholarships` series of meetings",
        to: "/mm/page/23",
      },
      {
        image: require("../../assets/images/miningMongolia/home-pages-cards/monthly member logo-01.png"),
        image_en: require("../../assets/images/miningMongolia/home-pages-cards/monthly member logo-02.png"),
        text_en:
          "Members of the Mongolian National Mining Association are highlighted every month",
        to: "/mm/page/24",
      },
    ];

    const router = useRouter();
    const goToPage = (page) => {
      router.push(page);
    };

    return { lang, projects, goToPage };
  },
};
</script>
