<template>
  <MWBackground>
    <div class="relative min-h-screen"></div>
  </MWBackground>

  <MWImageSwiper />
</template>

<script>
import MWBackground from "@/components/MiningWeek/MW2024/MWBackground.vue";
import MWImageSwiper from "@/containers/MiningWeek/MWImageSwiper.vue";

export default {
  name: "MWTest",
  components: {
    MWBackground,
    MWImageSwiper,
  },
  setup() {
    return {};
  },
};
</script>
