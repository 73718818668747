import App from "./App.vue";
import { createApp } from "vue";
import router from "./router";
import store from "./store";
import "./index.css";
import "animate.css";
import CKEditor from "@ckeditor/ckeditor5-vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import { MotionPlugin } from "@vueuse/motion";
import vueCountryRegionSelect from "vue3-country-region-select";

const app = createApp(App);
library.add(faUserSecret, fas, fab, far);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router);
app.use(store);
app.use(MotionPlugin);
app.use(CKEditor);
app.use(vueCountryRegionSelect);
app.mount("#app");
