import adminInstance from "@/lib/adminInstance";

export default {
  namespaced: true,
  state() {
    return {
      category: [],
      newsCategory: [],
      admin: {},
    };
  },

  getters: {
    getCategory(state) {
      return state.category;
    },

    getNewsCategory(state) {
      return state.newsCategory;
    },

    getAdminInformation(state) {
      return state.admin;
    },
  },

  mutations: {
    setCategory(state, payload) {
      state.category = payload;
    },

    setNewsCategory(state, payload) {
      state.newsCategory = payload;
    },

    setAdminInformation(state, payload) {
      state.admin = payload;
    },
  },

  actions: {
    async fetchAllCategories({ commit }) {
      try {
        const response = await adminInstance.get("/get/category");
        if (response.status === 200) {
          commit("setCategory", response.data.categories);
        }
      } catch (err) {
        return err;
      }
    },

    async fetchNewsCategory({ commit }) {
      try {
        const response = await adminInstance.get(
          `/get/category/page-type?type=news`
        );
        if (response.status === 200) {
          commit("setNewsCategory", response.data.category);
        }
      } catch (err) {
        return err;
      }
    },

    async fetchAdminInformation({ commit }, payload) {
      try {
        const response = await adminInstance.post("/auth/login", payload);
        if (response.status === 200) {
          commit("setAdminInformation", response.data.account);
          return response;
        }
      } catch (err) {
        return err;
      }
    },
  },
};
