<template>
  <hot-table :settings="hotSettings" :data="data"></hot-table>
</template>

<script>
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";

registerAllModules();

export default {
  name: "MseTradeTable",
  props: {
    data: Array,
  },
  components: {
    HotTable,
  },
  setup(props) {
    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center w-full p-1 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const hotSettings = {
      colHeaders: [
        "Худалдагч",
        "Бүтээгдэхүүн",
        "Доод үнэ",
        "Хэлцлийн үнэ",
        "Зохион байгуулагдсан огноо",
        "Дэлгэрэнгүй",
      ],
      columns: [
        {
          data: "seller_name",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "product_name",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "min_price",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "price_currency",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "trade_datetime",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          renderer: function (inst, td, row) {
            const own_url = props.data[row].own_url;
            const url = props.data[row].url;
            const link = own_url ? own_url : url;
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-1">
                  <a href=${link} target="_blank" style="color:#3b82f6;">Харах</a>
              </div>`;
          },
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "60vh",
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    return { hotSettings };
  },
};
</script>
