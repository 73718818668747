<template>
  <MWBackground>
    <div class="flex justify-center w-full py-10 z-10">
      <main
        class="min-h-screen w-full min-[1200px]:w-4/5 min-h-[150vh] max-[1199px]:px-5 text-white"
      >
        <MWOrganiserLogos class="flex justify-start items-center w-full" />

        <section class="flex max-md:flex-col gap-8 text-white mt-10">
          <div class="w-full">
            <h1 class="bg-[#009269] w-[10rem] py-2 uppercase text-center">
              {{ lang === "mn" ? "Хаяг" : "Address" }}
            </h1>
            <p class="flex gap-3 items-center mt-2">
              <font-awesome-icon
                icon="fa-solid fa-location-dot"
                class="sm:text-xl"
              />
              <span class="max-sm:text-sm">{{
                lang === "mn"
                  ? "Монгол улс, Улаанбаатар хот, Сүхбаатар дүүрэг, Элчингийн гудамж-9, “Скай Плаза” Бизнес төв, Д блок, 2-р давхар"
                  : "2nd floor, “Sky Plaza” Business center, Olympic street-12, Sukhbaatar district, Ulaanbaatar, Mongolia"
              }}</span>
            </p>

            <h1 class="bg-[#009269] w-[10rem] py-2 uppercase text-center mt-8">
              {{ lang === "mn" ? "Холбоо барих" : "Contact" }}
            </h1>
            <p
              class="flex max-lg:flex-col gap-2 lg:gap-8 lg:items-center mt-2 max-sm:text-sm"
            >
              <span>
                <p class="flex items-center gap-2">
                  <font-awesome-icon icon="fa-solid fa-phone" />+976 7011 0163
                </p>
                <p class="flex items-center gap-2">
                  <font-awesome-icon icon="fa-solid fa-phone" />+976 8888 9878
                </p>
              </span>
              <span>
                <p class="flex items-center gap-2">
                  <font-awesome-icon
                    icon="fa-solid fa-envelope"
                  />miningweek@miningmongolia.mn
                </p>
                <p class="flex items-center gap-2">
                  <font-awesome-icon
                    icon="fa-solid fa-envelope"
                  />info@miningmongolia.mn
                </p>
              </span>
            </p>

            <h1 class="bg-[#009269] w-[10rem] py-2 uppercase text-center mt-8">
              {{ lang === "mn" ? "Суваг" : "Social media" }}
            </h1>
            <div class="flex gap-2 items-center mt-2 max-sm:text-sm">
              <a
                href="https://www.facebook.com/miningweek"
                target="_blank"
                class="flex justify-center items-center w-8 h-8 rounded-full bg-white"
              >
                <font-awesome-icon
                  icon="fa-brands fa-facebook-f"
                  class="text-[#001F3D]"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/mongolian-national-mining-association"
                target="_blank"
                class="flex justify-center items-center w-8 h-8 rounded-full bg-white"
              >
                <font-awesome-icon
                  icon="fa-brands fa-linkedin-in"
                  class="text-[#001F3D]"
                />
              </a>
              <a
                href="https://twitter.com/mining_week"
                target="_blank"
                class="flex justify-center items-center w-8 h-8 rounded-full bg-white"
              >
                <font-awesome-icon
                  icon="fa-brands fa-x-twitter"
                  class="text-[#001F3D]"
                />
              </a>
              <a
                href="https://www.instagram.com/miningweek"
                target="_blank"
                class="flex justify-center items-center w-8 h-8 rounded-full bg-white"
              >
                <font-awesome-icon
                  icon="fa-brands fa-instagram"
                  class="text-[#001F3D]"
                />
              </a>
              <p>@winingweek</p>
            </div>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.1640691233565!2d106.92311487660623!3d47.91386277121976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9692382b1e57f5%3A0x4ac60a62935dde92!2sSKY%20PLAZA!5e0!3m2!1sen!2smn!4v1726456153433!5m2!1sen!2smn"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            class="w-full"
          ></iframe>
        </section>
      </main>
    </div>
  </MWBackground>
</template>

<script>
import MWBackground from "@/components/MiningWeek/MW2024/MWBackground.vue";
import MWOrganiserLogos from "@/components/MiningWeek/MW2024/MWOrganiserLogos";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "MWContactPage",
  components: {
    MWBackground,
    MWOrganiserLogos,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    return { lang };
  },
};
</script>

<style scoped>
i::before {
  background: transparent;
}
</style>
