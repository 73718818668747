import axios from "axios";

// const baseURL = "http://localhost:3000/admin";

const baseURL = "https://miningmongolia.mn/admin";

const adminInstance = axios.create({
  baseURL,
  withCredentials: true,
});

export default adminInstance;
