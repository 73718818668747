<template>
  <main class="space-y-7">
    <!-- search -->
    <MW2023SideContainer :title="'Хайлт'" :title_en="'Search Blog'">
      <form action="" class="flex">
        <input
          name="keyWord"
          id="keyWord"
          type="text"
          :placeholder="
            lang === 'mn' ? 'Хайлт хийх ......' : 'Search news ......'
          "
          class="py-4 px-2 bg-blue-50 text-blue-400 w-full placeholder:text-blue-400 placeholder:text-xs"
          v-model="keyWord"
        />
        <router-link
          :to="`/mm/blog/${searchTerm}=${1}`"
          class="flex justify-center items-center w-14 h-14 bg-[#00a375] text-white"
        >
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
        </router-link>
      </form>
    </MW2023SideContainer>

    <!-- categories -->
    <MW2023SideContainer :title="'Ангилал'" :title_en="'Categories'">
      <div class="w-full space-y-3">
        <router-link
          v-for="cat in categories"
          :key="cat.id"
          :to="`/mw/2023/news/${cat.id}=${1}`"
          class="w-full flex text-white text-sm"
        >
          <span class="flex items-center w-4/5 bg-green p-2">{{
            lang === "mn" ? cat.category : cat.category_en
          }}</span>
          <span
            class="flex justify-center items-center w-1/5 bg-lightGreen p-2"
            >{{ cat.news_count }}</span
          >
        </router-link>
      </div>
    </MW2023SideContainer>
  </main>
</template>

<script>
import MW2023SideContainer from "@/components/MiningWeek/MW2023/MW2023SideContainer.vue";
import clientMWInstance from "@/lib/clientMWInstance";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "MW2023NewsSide",
  components: {
    MW2023SideContainer,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const keyWord = ref("");
    const newsCategories = ref([]);

    const searchTerm = computed(() => (keyWord.value ? keyWord.value : 8));

    const fetchSideData = async () => {
      try {
        const res = await clientMWInstance.get("/get/news-category");
        if (res.status === 200) newsCategories.value = res.data.categories;
      } catch (err) {
        return err;
      }
    };

    onMounted(async () => {
      await fetchSideData();
    });

    return { lang, keyWord, searchTerm };
  },
};
</script>
