<template>
  <AdminNavbar>
    <div v-if="!isLoading && data.length > 0" class="space-y-5">
      <div v-for="item in data" :key="item.cid" class="bg-white rounded shadow">
        <h6
          class="text-base font-bold border-t-4 border-t-blue-500 rounded-t border-b p-3"
        >
          {{ item.category }}
        </h6>
        <div class="p-3 space-y-1">
          <button
            @click="toggleMongolianData(item)"
            class="flex justify-between items-center p-3 w-full bg-slate-300 text-gray-800"
          >
            <span>Монгол хэлээр</span>
            <font-awesome-icon
              v-if="item.isMongolianData"
              icon="fa-solid fa-angle-up"
            />
            <font-awesome-icon v-else icon="fa-solid fa-angle-down" />
          </button>

          <div v-if="item.isMongolianData" class="py-5">
            <div class="prose" style="max-width: 1100px">
              <ckeditor
                :editor="Editor"
                v-model="item.text"
                :config="editorConfig"
              />
            </div>
          </div>

          <button
            @click="toggleEnglishData(item)"
            class="flex justify-between items-center p-3 w-full bg-slate-300 text-gray-800"
          >
            <span>Англи хэлээр</span>
            <font-awesome-icon
              v-if="item.isEnglishData"
              icon="fa-solid fa-angle-up"
            />
            <font-awesome-icon v-else icon="fa-solid fa-angle-down" />
          </button>

          <div v-if="item.isEnglishData" class="py-5">
            <div class="prose" style="max-width: 1100px">
              <ckeditor
                :editor="Editor"
                v-model="item.text_en"
                :config="editorConfig"
              />
            </div>
          </div>

          <div class="mt-5">
            <button
              @click="editPage(item)"
              class="bg-[#28a745] hover:bg-[#208537] rounded py-2 px-4 mt-5 text-white"
            >
              Шинэчлэх
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="isLoading"
      class="flex justify-center items-center h-[50vh]"
    >
      <SpinLoading :color="'rgb(59 130 246)'" />
    </div>
  </AdminNavbar>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import AdminNavbar from "@/components/admin/AdminNavbar";
import adminInstance from "@/lib/adminInstance";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import SpinLoading from "@/components/ui/SpinLoading.vue";

export default {
  name: "MMEditStatic",
  components: {
    AdminNavbar,
    SpinLoading,
  },
  setup() {
    const route = useRoute();
    const data = ref([]);
    const isLoading = ref(true);

    const fetchData = async (pid) => {
      try {
        const res = await adminInstance.get(`/get/static-data?pid=${pid}`);
        const items = res.data.static;

        items.forEach((item) => {
          if (items.length > 1) {
            item.isMongolianData = false;
            item.isEnglishData = false;
          } else {
            item.isMongolianData = true;
            item.isEnglishData = true;
          }
          item.text = item.text || "";
          item.text_en = item.text_en || "";
        });
        data.value = items;
      } catch (err) {
        console.error(err);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchData(route.params.cid);
    });

    watch(
      () => route.params.cid,
      (newCid) => {
        fetchData(newCid);
      }
    );

    const toggleMongolianData = (item) => {
      item.isMongolianData = !item.isMongolianData;
    };

    const toggleEnglishData = (item) => {
      item.isEnglishData = !item.isEnglishData;
    };

    const editPage = async (data) => {
      const formData = {
        id: data.id || null,
        category_cid: data.cid,
        text: data.text || null,
        text_en: data.text_en || null,
      };

      try {
        if (data.id === null) {
          const res = await adminInstance.post("/insert/static-data", formData);
          if (res.status === 200) window.location.reload();
        } else {
          const res = await adminInstance.post("/update/static-data", formData);
          if (res.status === 200) window.location.reload();
        }
      } catch (err) {
        return err;
      }
    };

    /* CKEDITOR */
    class MyUploadAdapter {
      constructor(loader) {
        this.loader = loader;
      }

      upload() {
        return this.loader.file.then(
          (file) =>
            new Promise((resolve, reject) => {
              this._initRequest();
              this._initListeners(resolve, reject, file);
              this._sendRequest(file);
            })
        );
      }

      abort() {
        if (this.xhr) {
          this.xhr.abort();
        }
      }

      _initRequest() {
        const xhr = (this.xhr = new XMLHttpRequest());
        xhr.open(
          "POST",
          "https://miningmongolia.mn/admin/static/ckupload",
          true
        );
        xhr.responseType = "json";
      }

      _initListeners(resolve, reject, file) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${file.name}.`;

        xhr.addEventListener("error", () => reject(genericErrorText));
        xhr.addEventListener("abort", () => reject());
        xhr.addEventListener("load", () => {
          const response = xhr.response;

          if (!response || response.error) {
            return reject(
              response && response.error
                ? response.error.message
                : genericErrorText
            );
          }

          resolve({
            default: response.url[0],
          });
        });

        if (xhr.upload) {
          xhr.upload.addEventListener("progress", (evt) => {
            if (evt.lengthComputable) {
              loader.uploadTotal = evt.total;
              loader.uploaded = evt.loaded;
            }
          });
        }
      }

      _sendRequest(file) {
        const data = new FormData();
        data.append("image", file);
        this.xhr.send(data);
      }
    }

    function MyCustomUploadAdapterPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    }

    const editorConfig = {
      extraPlugins: [MyCustomUploadAdapterPlugin],
      removePlugins: ["MediaEmbedToolbar", "Markdown"],
      alignment: {
        options: ["left", "right", "center", "justify"],
      },
      language: "en",
      licenseKey: "",
    };

    return {
      isLoading,
      data,
      toggleMongolianData,
      toggleEnglishData,
      Editor,
      editorConfig,
      editPage,
    };
  },
};
</script>

<style>
.ck-editor__editable {
  min-height: 500px;
}
</style>
