<template>
  <admin-navbar>
    <h1 class="font-bold text-lg">
      Хөрөнгийн бирж - Уул уурхайн бүтээгдэхүүн арилжаа
    </h1>

    <div class="bg-white rounded shadow">
      <h6 class="text-base border-t-4 border-t-blue-500 border-b rounded-t p-3">
        Арилжааны мэдээлэл
      </h6>

      <div class="flex items-center bg-gray-200 rounded text-xs p-2 m-3">
        <label for="startDate" class="mr-2">Эхлэх огноо:</label>
        <input
          type="date"
          class="border rounded p-1 mr-5 w-[10rem]"
          :class="!startDate && isSubmitted ? 'border-red-500' : ''"
          v-model="startDate"
          :max="today"
        />

        <label for="endDate" class="mr-2">Дуусах огноо:</label>
        <input
          type="date"
          class="border rounded p-1 mr-5 w-[10rem]"
          :class="!endDate && isSubmitted ? 'border-red-500' : ''"
          v-model="endDate"
        />

        <button
          @click="filterMseData()"
          class="bg-blue-500 p-1 rounded text-white mr-2"
        >
          <font-awesome-icon icon="fa-solid fa-filter" class="mr-1" />Шүүх
        </button>
        <button
          @click="resetFilter()"
          class="bg-amber-500 p-1 rounded text-white"
        >
          <font-awesome-icon icon="fa-solid fa-eraser" class="mr-1" />Арилгах
        </button>
      </div>

      <div class="p-3 space-y-5 text-xs">
        <div class="flex justify-between items-center">
          <div class="space-x-3">
            <label for="category">Ангилал:</label>
            <select
              name="category"
              id="category"
              class="p-1 border rounded"
              v-model="selectedCategory"
            >
              <option value="" disabled>Ангиллын төрөл</option>
              <option value="notice">Арилжааны хуваарь /notice/</option>
              <option value="trade">Арилжааны мэдээлэл /trade/</option>
            </select>
          </div>

          <div class="space-x-3">
            <span>Хуудас бүрт:</span>
            <select
              name="dataPerPage"
              id="dataPerPage"
              class="border rounded p-1 text-blue-500"
              v-model="dataPerPage"
            >
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

          <span>Нийт арилжааны тоо: {{ totalTradeCount }} </span>

          <div class="flex items-center gap-3">
            <span>Нийт хуудас: {{ totalPages }} </span>
            <PaginationCart
              :totalPages="totalPages"
              :modelValue="currentPage"
              :style="{
                color: 'blue-500',
                height: 7,
                width: 7,
                fontSize: 'xs',
              }"
              @page-changed="onPageChanged"
            />
          </div>
        </div>

        <div v-if="!isLoading && tradeData.length > 0">
          <MseTradeTable
            v-if="selectedCategory === 'trade'"
            :data="tradeData"
          />
          <MseNoticeTable v-else :data="tradeData" />
        </div>

        <div
          v-else-if="!isLoading && tradeData.length === 0"
          class="flex justify-center items-center h-[60vh]"
        >
          Мэдээлэл хоосон байна
        </div>

        <div v-else class="flex justify-center items-center h-[60vh]">
          <spin-loading />
        </div>
      </div>
    </div>
  </admin-navbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import { onMounted, ref, watch, computed } from "vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import adminInstance from "@/lib/adminInstance";
import PaginationCart from "@/components/ui/PaginationCart.vue";
import MseTradeTable from "@/components/admin/MiningMongolia/MseTradeTable.vue";
import MseNoticeTable from "@/components/admin/MiningMongolia/MseNoticeTable.vue";

export default {
  name: "MMMSETrade",
  components: {
    AdminNavbar,
    SpinLoading,
    PaginationCart,
    MseTradeTable,
    MseNoticeTable,
  },
  setup() {
    const isLoading = ref(false);
    const tradeData = ref([]);
    const selectedCategory = ref("trade");

    const dataPerPage = ref(20);
    const totalTradeCount = ref(null);
    const totalPages = ref(1);
    const currentPage = ref(1);

    const fetchTradeData = async () => {
      try {
        let res;
        isLoading.value = true;
        if (selectedCategory.value === "trade") {
          res = await adminInstance.get(
            `/get/trade?page=${currentPage.value}&limit=${dataPerPage.value}&startDate=${startDate.value}&endDate=${endDate.value}`
          );
        } else {
          res = await adminInstance.get(
            `/get/notice?page=${currentPage.value}&limit=${dataPerPage.value}&startDate=${startDate.value}&endDate=${endDate.value}`
          );
        }
        tradeData.value = res.data.result;
        totalPages.value = res.data.totalPages;
        totalTradeCount.value = res.data.total;
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchTradeData();
    });

    watch([() => dataPerPage.value, () => selectedCategory.value], () => {
      currentPage.value = 1;
      fetchTradeData();
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchTradeData();
    };

    const startDate = ref(null);
    const endDate = ref(null);
    const success = ref(null);
    const isSubmitted = ref(false);

    const validateDate = () => {
      return startDate.value && endDate.value;
    };

    const filterMseData = async () => {
      isSubmitted.value = true;

      if (!validateDate()) {
        return;
      }

      await fetchTradeData();
    };

    const resetFilter = async () => {
      startDate.value = null;
      endDate.value = null;
      isSubmitted.value = false;
      await fetchTradeData();
    };

    const today = computed(() => {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    });

    return {
      isLoading,
      tradeData,
      dataPerPage,
      totalTradeCount,
      totalPages,
      currentPage,
      onPageChanged,
      selectedCategory,
      filterMseData,
      startDate,
      endDate,
      success,
      isSubmitted,
      resetFilter,
      fetchTradeData,
      today,
    };
  },
};
</script>
