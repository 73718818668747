import axios from "axios";

// const baseURL = "http://localhost:3000/client";

const baseURL = "https://miningmongolia.mn/client";

const clientInstance = axios.create({
  baseURL,
  withCredentials: true,
});

export default clientInstance;
