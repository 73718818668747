import axios from "axios";

// const baseURL = "http://localhost:3000/mw/admin";

const baseURL = "https://miningweek.mn/mw/admin";

const adminMWInstance = axios.create({
  baseURL,
  withCredentials: true,
});

export default adminMWInstance;
