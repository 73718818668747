import axios from "axios";

// const baseURL = "http://localhost:3000/mw/client";

const baseURL = "https://miningweek.mn/mw/client";

const clientMWInstance = axios.create({
  baseURL,
  withCredentials: true,
});

export default clientMWInstance;
