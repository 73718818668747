<template>
  <div
    class="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-[1500] h-screen w-full"
  >
    <main class="relative flex justify-center items-center h-screen w-4/5 p-10">
      <button
        @click="closeSwiper"
        class="absolute top-5 right-5 flex justify-center items-center bg-white w-8 h-8 rounded-full text-lg hover:bg-[#009269] hover:text-white"
      >
        <font-awesome-icon icon="fa-solid fa-xmark" />
      </button>

      <swiper
        :modules="modules"
        :slides-per-view="1"
        :navigation="true"
        :initial-slide="startIndex"
        :loop="true"
        class="customSwiper h-full w-full"
      >
        <swiper-slide
          v-for="(image, index) in images"
          :key="index"
          class="flex justify-center items-center"
        >
          <img
            :src="image.image"
            :alt="`image-${index}`"
            class="w-4/5 object-contain"
          />
        </swiper-slide>
      </swiper>
    </main>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css/bundle";

export default {
  name: "MWImageSwiper",
  props: {
    images: {
      type: Array,
      required: true,
    },
    startIndex: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup(props, { emit }) {
    const closeSwiper = () => {
      emit("close");
    };

    return {
      closeSwiper,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
};
</script>

<style scoped>
.customSwiper {
  position: relative;
}

.customSwiper :deep(.swiper-button-next),
.customSwiper :deep(.swiper-button-prev) {
  background-color: white;
  opacity: 50%;
  color: #009269;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.customSwiper :deep(.swiper-button-next::after),
.customSwiper :deep(.swiper-button-prev::after) {
  font-size: 20px;
}

.customSwiper :deep(.swiper-button-next:hover),
.customSwiper :deep(.swiper-button-prev:hover) {
  background-color: #009269;
  color: white;
  opacity: 100%;
}
</style>

<!-- <template>
  <div
    class="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-20 h-full w-full"
  >
    <main class="p-20">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }"
        :navigation="true"
      >
        <swiper-slide v-for="(index, image) in images" :key="index">
          <img :src="image.image" :alt="index" class="h-full object-fit" />
        </swiper-slide>
      </swiper>
    </main>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css/bundle";

export default {
  name: "MWImageSwiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    images: {
      type: Array,
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
};
</script> -->
