<template>
  <main class="flex justify-center w-full">
    <div class="w-full min-[1200px]:w-4/5 max-[1199px]:p-5">
      <div
        v-if="noContent"
        class="min-h-[50vh] flex justify-center items-center md:text-2xl text-gray-300"
      >
        {{ lang === "mn" ? "Хуудас хоосон байна" : "No Content" }}
      </div>

      <div
        v-else-if="!noContent && !isLoading"
        class="space-y-10 my-10 min-h-[50vh]"
      >
        <div v-for="data in staticData" :key="data.cid" :id="data.category">
          <div class="prose" style="max-width: 1200px">
            <p
              v-if="lang === 'mn' ? data.text : data.text_en"
              v-html="lang === 'mn' ? data.text : data.text_en"
            ></p>
          </div>

          <!-- Хариуцлагатай Уул Уурхай -->
          <div v-if="data.cid === 20" class="mt-5">
            <h1 v-if="documents.length > 0" class="font-bold text-lg uppercase">
              {{
                lang === "mn" ? "Холбогдох материалууд:" : "Related materials:"
              }}
            </h1>
            <div
              v-if="documents.length > 0"
              class="grid grid-cols-3 gap-7 mt-3"
            >
              <button
                v-for="doc in documents"
                :key="doc.id"
                @click="downloadFile(doc.pdf, doc.title)"
                class="bg-gray-100 py-3 text-green text-sm"
              >
                <font-awesome-icon icon="fa-solid fa-download" class="mr-2" />
                {{ doc.title }}
              </button>
            </div>

            <h1 class="font-bold text-lg uppercase mt-8">
              {{ lang === "mn" ? "Өөрийн үнэлгээ" : "Self-assessment" }}
            </h1>
            <router-link
              to="/mm/self-assessment"
              class="flex justify-between items-center bg-blue-500 p-3 text-white w-[17rem] hover:scale-105 mt-3"
            >
              <span>
                <font-awesome-icon icon="fa-solid fa-list-check" class="mr-1" />
                Өөрийн үнэлгээг хийх
              </span>
              <font-awesome-icon icon="fa-solid fa-angle-right" />
            </router-link>
          </div>

          <div
            v-if="data.cid === 22"
            class="grid grid-cols-1 sm:grid-cols-2 gap-10 mt-5"
          >
            <div v-for="video in videos" :key="video.id" class="w-full">
              <h1
                class="bg-gradient-to-r from-gray-100 to-white text-green font-bold tracking-wide uppercase px-3 py-1 border-l-4 border-green"
              >
                {{ video.title }}
              </h1>
              <video class="w-full mt-5" controls>
                <source :src="video.video" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else-if="!noContent && isLoading"
        class="flex justify-center items-center w-full h-[50vh]"
      >
        <SpinLoading />
      </div>
    </div>
  </main>
</template>

<script>
import { onMounted, ref, watch, computed } from "vue";
import clientInstance from "@/lib/clientInstance";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import SpinLoading from "@/components/ui/SpinLoading.vue";

export default {
  name: "MMProjectPage",
  components: { SpinLoading },
  setup() {
    const route = useRoute();
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const isLoading = ref(true);
    const staticData = ref([]);
    const documents = ref([]);
    const videos = ref([]);
    const noContent = ref(false);

    const fetchData = async (categoryId) => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get(
          `/get/static-page?pid=${categoryId}`
        );
        staticData.value = res.data.static;
        if (categoryId === "20") {
          const docRes = await clientInstance.get("/get/document-pdf");
          if (docRes.status === 200) {
            documents.value = docRes.data.document;
          }
        }
        if (categoryId === "22") {
          const videoRes = await clientInstance.get("/get/mining-video");
          if (videoRes.status === 200) {
            videos.value = videoRes.data.video;
          }
        }
        checkNoContent();
      } catch (err) {
        console.error(err);
      } finally {
        isLoading.value = false;
      }
    };

    const checkNoContent = () => {
      if (lang.value === "mn") {
        noContent.value = staticData.value.every((data) => !data.text);
      } else {
        noContent.value = staticData.value.every((data) => !data.text_en);
      }
    };

    onMounted(() => {
      fetchData(route.params.cid);
    });

    watch(
      () => route.params.cid,
      (newCid) => {
        fetchData(newCid);
      }
    );

    watch(lang, () => {
      checkNoContent();
    });

    const downloadFile = (pdfUrl, title) => {
      fetch(pdfUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", title + ".pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error("Error downloading file:", error));
    };

    return {
      lang,
      isLoading,
      staticData,
      noContent,
      documents,
      downloadFile,
      videos,
    };
  },
};
</script>
