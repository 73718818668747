<template>
  <main class="flex justify-center">
    <div
      class="flex max-[800px]:flex-col gap-5 lg:gap-10 w-full min-[1200px]:w-4/5 max-[1199px]:p-5 py-5"
    >
      <!-- NEWS DETAIL -->
      <div
        v-if="!isLoading && news"
        class="w-full h-fit border shadow p-5 lg:p-10 space-y-5"
      >
        <img :src="news.image" :alt="news.id" class="w-full" />
        <h1 class="font-medium text-2xl">
          {{ lang === "mn" ? news.title : news.title_en }}
        </h1>
        <div
          class="flex max-sm:flex-col items-center text-sm font-light text-gray-500"
        >
          <font-awesome-icon icon="fa-solid fa-user" class="mr-2" />
          <span class="mr-8">{{ news.publisher }}</span>
          <font-awesome-icon icon="fa-solid fa-calendar-days" class="mr-2" />
          <span>{{ news.created_at }}</span>
        </div>
        <p
          v-html="lang === 'mn' ? news.text : news.text_en"
          class="leading-6 text-pretty"
        ></p>

        <!-- share -->
        <div class="flex flex-col justify-center items-center p-5 bg-gray-200">
          <h3 class="text-xl mb-2 tracking-wide">
            {{ lang === "mn" ? "Хуваалцах :" : "Share" }}
          </h3>
          <div class="flex gap-1">
            <button
              @click="shareOnFacebook"
              class="flex justify-center items-center h-8 w-8 bg-[#1877F2] rounded text-white text-xl"
            >
              <font-awesome-icon icon="fa-brands fa-facebook" />
            </button>
            <button
              @click="shareOnTwitter"
              class="flex justify-center items-center h-8 w-8 bg-[#1DA1F2] rounded text-white text-xl"
            >
              <font-awesome-icon icon="fa-brands fa-twitter" />
            </button>
            <button
              @click="shareByEmail"
              class="flex justify-center items-center h-8 w-8 bg-gray-500 rounded text-white text-xl"
            >
              <font-awesome-icon icon="fa-solid fa-envelope" />
            </button>
            <!-- <button
              class="flex justify-center items-center h-8 w-8 bg-[rgb(1_102_255)] rounded text-white text-xl"
            >
              <font-awesome-icon icon="fa-solid fa-add" />
            </button> -->
          </div>
        </div>
      </div>

      <div
        v-else-if="!isLoading && !news"
        class="flex justify-center items-center w-full h-[10rem] md:text-2xl bg-gray-100"
      >
        {{ lang === "mn" ? "Мэдээ олдсонгүй" : "No News Found" }}
      </div>

      <div
        v-else-if="isLoading"
        class="flex justify-center items-center w-full h-[50vh]"
      >
        <SpinLoading />
      </div>

      <NewsPageSide />
    </div>
  </main>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import clientInstance from "@/lib/clientInstance";
import NewsPageSide from "@/containers/MiningMongolia/NewsPageSide.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";

export default {
  name: "MMDetailNews",
  components: { NewsPageSide, SpinLoading },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const route = useRoute();
    const isLoading = ref(false);
    const news = ref(null);

    const fetchNews = async (newsId) => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get(
          `/get/news/id?id=${newsId}&lang=${lang.value}`
        );
        if (res.status === 200 && res.data.news) {
          news.value = res.data.news;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchNews(route.params.id || 1);
    });

    watch([() => route.params.id, lang], ([newId]) => {
      fetchNews(newId || 1);
      news.value = null;
    });

    const shareOnFacebook = () => {
      const url = encodeURIComponent(window.location.href);
      const title = encodeURIComponent(news.value.title || "Mining Mongolia");
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${title}`,
        "_blank"
      );
    };

    const shareOnTwitter = () => {
      const url = encodeURIComponent(window.location.href);
      const title = encodeURIComponent(news.value.title || "Mining Mongolia");
      window.open(
        `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
        "_blank"
      );
    };

    const shareByEmail = () => {
      const url = encodeURIComponent(window.location.href);
      const title = encodeURIComponent(news.value.title || "Mining Mongolia");
      window.location.href = `mailto:?subject=${title}&body=${url}`;
    };

    return {
      lang,
      news,
      isLoading,
      shareOnFacebook,
      shareOnTwitter,
      shareByEmail,
    };
  },
};
</script>
