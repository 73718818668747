<template>
  <AdminNavbar>
    <h1 class="font-bold text-lg">Холбогдох мэдээлэл</h1>
    <div class="rounded bg-white">
      <div class="border-b p-3 border-t-4 border-t-blue-500 rounded-t">
        <h6 class="text-base">Footer хэсгийн холбоо барих мэдээлэл</h6>
      </div>
      <div class="p-3 space-y-5">
        <div v-if="!isLoading && footerInfo.length > 0">
          <hot-table :settings="hotSettings" :data="footerInfo"></hot-table>
        </div>

        <div
          v-else-if="footerInfo.length === 0 && !isLoading"
          class="flex justify-center items-center h-[40vh]"
        >
          Жагсаалт хоосон байна
        </div>

        <div v-else class="flex justify-center items-center h-[40vh]">
          <SpinLoading :color="'rgb(59 130 246)'" />
        </div>

        <!-- add info -->
        <div class="rounded shadow w-2/3">
          <h6 class="border-b p-3">Нэмэх / Засварлах</h6>

          <div class="text-sm">
            <div class="flex items-center p-3">
              <label for="type" class="font-bold w-[9rem]"
                >Төрөл<span class="text-red-500">*</span></label
              >
              <select
                name="type"
                id="type"
                class="border rounded p-2 w-full"
                v-model="form.type"
              >
                <option value="phone">Утасны дугаар</option>
                <option value="email">И-мэйл хаяг</option>
                <option value="link">Вэбсайт линк</option>
              </select>
            </div>

            <div class="flex items-center px-3 pb-3">
              <label for="information" class="font-bold w-[9rem]"
                >Мэдээлэл<span class="text-red-500">*</span></label
              >
              <input
                name="information"
                id="information"
                type="text"
                class="border rounded p-2 w-full"
                v-model="form.information"
              />
            </div>

            <div class="flex bg-gray-200 gap-5 p-3 text-xs text-white">
              <button
                @click="uploadFooter()"
                class="p-2"
                :class="
                  form.id
                    ? 'bg-sky-500 hover:bg-sky-600'
                    : 'bg-[#28a745] hover:bg-[#208537]'
                "
              >
                <font-awesome-icon
                  :icon="form.id ? 'fa-solid fa-pen' : 'fa-solid fa-add'"
                  class="mr-1"
                />{{ form.id ? "Засварлах" : "Нэмэх" }}
              </button>

              <button
                @click="resetForm"
                class="p-2 bg-orange-400 hover:bg-orange-500"
              >
                <font-awesome-icon
                  icon="fa-solid fa-eraser"
                  class="mr-1"
                />Арилгах
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import adminMWInstance from "@/lib/adminMWInstance";
import { onMounted, ref } from "vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";

registerAllModules();

export default {
  name: "MWUpdateFooter",
  components: {
    AdminNavbar,
    SpinLoading,
    HotTable,
  },
  setup() {
    const isLoading = ref(false);
    const footerInfo = ref([]);

    const fetchFooterInformation = async () => {
      try {
        isLoading.value = true;
        const res = await adminMWInstance.get("/get/footer");
        if (res.status === 200) {
          footerInfo.value = res.data.footer;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchFooterInformation();
    });

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center w-full p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const typeRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      let displayValue;
      switch (value) {
        case "phone":
          displayValue = "Утасны дугаар";
          break;
        case "email":
          displayValue = "И-мэйл хаяг";
          break;
        case "link":
          displayValue = "Вэбсайт линк";
          break;
        default:
          displayValue = "";
      }
      td.innerHTML = `<span class="flex justify-center w-full p-2 text-black">${displayValue}</span>`;
      return td;
    };

    const hotSettings = {
      colHeaders: ["Үйлдэл", "Төрөл", "Мэдээлэл"],
      columns: [
        {
          renderer: function (inst, td, row) {
            const footer = footerInfo.value[row];
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-2 gap-2 text-white">
                  <button class="flex items-center bg-sky-500 py-1 px-2 rounded edit-btn">
                      <svg class="fill-current w-3 h-3 mr-1" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
                      <span>Засварлах</span>
                  </button>
                  <button class="flex justify-center items-center bg-red-500 py-1 px-2 rounded delete-btn">
                      <svg class="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
                      <span>Устгах</span>
                  </button>
              </div>`;

            const editButton = td.querySelector(".edit-btn");
            if (editButton) {
              editButton.addEventListener("click", () => {
                editFooter(footer);
              });
            }

            const deleteButton = td.querySelector(".delete-btn");
            if (deleteButton) {
              deleteButton.addEventListener("click", () => {
                deleteFooter(footer);
              });
            }
          },
        },
        {
          data: "type",
          readOnly: true,
          renderer: typeRenderer,
        },
        {
          data: "information",
          readOnly: true,
          renderer: commonRenderer,
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "40vh",
      fixedColumnsLeft: 1,
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const editFooter = (footer) => {
      form.value = {
        id: footer.id,
        type: footer.type,
        information: footer.information,
      };
    };

    const deleteFooter = async (footer) => {
      if (!window.confirm(`${footer.information} устгах уу?`)) {
        return;
      }

      try {
        const res = await adminMWInstance.delete(
          `/delete/footer?id=${footer.id}`
        );
        if (res.status === 200) {
          await fetchFooterInformation();
        }
      } catch (err) {
        return err;
      }
    };

    // Add & Edit Footer
    const form = ref({
      id: null,
      type: null,
      information: null,
    });

    const resetForm = () => {
      form.value = {
        id: null,
        type: null,
        information: null,
      };
    };

    const isValid = () => {
      if (!form.value.type) return alert("Төрлийг оруулна уу");
      if (!form.value.information) return alert("Мэдээллийг оруулна уу");

      return true;
    };

    const uploadFooter = async () => {
      if (!isValid()) return;

      try {
        if (form.value.id) {
          const res = await adminMWInstance.post("/update/footer", form.value);
          if (res.status === 200) window.location.reload();
        } else {
          const res = await adminMWInstance.post("/insert/footer", form.value);
          if (res.status === 200) window.location.reload();
        }
      } catch (err) {
        return err;
      }
    };

    return {
      isLoading,
      footerInfo,
      hotSettings,
      form,
      resetForm,
      uploadFooter,
      editFooter,
      deleteFooter,
    };
  },
};
</script>
