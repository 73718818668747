<template>
  <AdminNavbar>
    <h1 class="text-lg font-bold">Хөтөлбөр</h1>

    <!-- Agenda List -->
    <div v-if="!editingMode" class="bg-white rounded shadow">
      <div
        class="flex justify-between items-center border-t-4 border-t-blue-500 rounded-t border-b p-3"
      >
        <h6 class="text-base">Өдөр бүрийн хөтөлбөр</h6>
        <button
          @click="setEditMode()"
          class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600"
        >
          <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />Хөтөлбөр
          нэмэх
        </button>
      </div>

      <div v-if="!isLoading" class="p-3 space-y-5">
        <!-- PAGINATION -->
        <div class="flex justify-between items-center text-xs">
          <div class="space-x-3">
            <span>Арга хэмжээ:</span>
            <select
              name="selectedEvent"
              id="selectedEvent"
              class="border rounded p-1 text-blue-500 max-w-[20rem]"
              v-model="selectedEvent"
            >
              <option
                v-for="cat in events"
                :key="cat.id"
                :value="cat.id"
                class="text-pretty"
              >
                {{ cat.event }}
              </option>
            </select>
          </div>

          <div class="space-x-3">
            <span>Хэл:</span>
            <select
              name="selectedLanguage"
              id="selectedLanguage"
              class="border rounded p-1 text-blue-500 max-w-[20rem]"
              v-model="selectedLanguage"
            >
              <option value="all" class="text-pretty">Бүгд</option>
              <option
                v-for="lang in languages"
                :key="lang.id"
                :value="lang.id"
                class="text-pretty"
              >
                {{ lang.language }}
              </option>
            </select>
          </div>

          <div class="space-x-3">
            <span>Хуудас бүрт:</span>
            <select
              name="pagePerPage"
              id="pagePerPage"
              class="border rounded p-1 text-blue-500"
              v-model="dataPerPage"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>

          <span>Нийт хөтөлбөрийн тоо: {{ totalAgendaCount }} </span>

          <div class="flex items-center gap-3">
            <span>Нийт хуудас: {{ totalPages }} </span>
            <PaginationCart
              :totalPages="totalPages"
              :modelValue="currentPage"
              :style="{
                color: 'blue-500',
                height: 7,
                width: 7,
                fontSize: 'xs',
              }"
              @page-changed="onPageChanged"
            />
          </div>
        </div>

        <div v-if="agenda.length > 0">
          <hot-table :settings="hotSettings" :data="agenda"></hot-table>
        </div>

        <div
          v-else-if="agenda.length === 0"
          class="flex justify-center items-center h-[70vh]"
        >
          Жагсаалт хоосон байна
        </div>
      </div>

      <div v-else class="flex justify-center items-center h-[70vh]">
        <SpinLoading :color="'rgb(59 130 246)'" />
      </div>
    </div>

    <!-- add new agenda -->
    <div v-else class="bg-white rounded shadow">
      <div
        class="flex justify-between items-center border-t-4 border-t-blue-500 rounded-t border-b p-3"
      >
        <h6 class="text-base">Хөтөлбөр шинээр нэмэх</h6>
        <div class="space-x-3">
          <button
            @click="cancelEditHandler()"
            class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600"
          >
            <font-awesome-icon icon="fa-solid fa-angles-left" class="mr-1" />
            Буцах
          </button>
          <router-link
            to="/admin/week/event-date"
            class="bg-[#28a745] p-2 text-white text-xs rounded hover:bg-[#208537]"
          >
            <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />
            Хөтөлбөрийн өдрүүд нэмэх
          </router-link>
        </div>
      </div>

      <div class="flex items-center w-full gap-2 p-3">
        <label for="language_id" class="font-bold w-1/5"
          >Хэл сонгох<span class="text-red-500">*</span></label
        >
        <select
          name="language_id"
          id="language_id"
          class="border rounded p-2 w-4/5"
          :class="
            isSubmitted && !form.language_id
              ? 'border-red-500'
              : 'border-gray-300'
          "
          v-model="form.language_id"
        >
          <option v-for="lang in languages" :key="lang.id" :value="lang.id">
            {{ lang.language }}
          </option>
        </select>
      </div>

      <div v-if="form.language_id" class="flex items-center w-full gap-2 p-3">
        <label for="event_date_id" class="font-bold w-1/5"
          >Хөтөлбөрийн огноо<span class="text-red-500">*</span></label
        >
        <select
          name="event_date_id"
          id="event_date_id"
          class="border rounded p-2 w-4/5"
          :class="
            isSubmitted && !form.event_date_id
              ? 'border-red-500'
              : 'border-gray-300'
          "
          v-model="form.event_date_id"
        >
          <option v-for="date in eventDates" :key="date.id" :value="date.id">
            {{ `${date.event} - ${date.day} - ${date.event_date}` }}
          </option>
        </select>
      </div>

      <div class="flex p-3 text-sm">
        <h4 class="w-1/5 font-bold">
          Хөтөлбөр<span class="text-red-500">*</span>
        </h4>
        <div class="space-y-3 w-4/5">
          <input
            type="file"
            class="w-full border rounded p-2"
            :class="
              isSubmitted && !mainImage ? 'border-red-500' : 'border-gray-300'
            "
            @change="onImageChange"
            accept="image/jpeg, image/png"
          />
          <span class="text-sky-500 text-xs">
            Зөвхөн jpg, jpeg, png өргөтгөлтэй зураг оруулна уу.
          </span>
          <div
            class="flex flex-col justify-center items-center"
            :class="mainImage ? 'w-3/4' : 'h-40 w-40'"
          >
            <img
              :src="imageSrc"
              alt="image"
              :class="!mainImage ? 'w-14 h-14' : ''"
            />
            <span v-if="!mainImage" class="text-xs text-gray-500"
              >Зураг байхгүй байна</span
            >
          </div>
        </div>
      </div>

      <div class="flex bg-white border rounded-b gap-5 p-3 text-xs text-white">
        <button
          @click="uploadAgenda()"
          class="p-2"
          :class="
            form.id
              ? 'bg-sky-500 hover:bg-sky-600'
              : 'bg-[#28a745] hover:bg-[#208537]'
          "
        >
          <font-awesome-icon
            :icon="form.id ? 'fa-solid fa-pen' : 'fa-solid fa-add'"
            class="mr-1"
          />{{ form.id ? "Хөтөлбөр засварлах" : "Хөтөлбөр нэмэх" }}
        </button>

        <button
          @click="resetForm()"
          class="p-2 bg-orange-400 hover:bg-orange-500"
        >
          <font-awesome-icon icon="fa-solid fa-eraser" class="mr-1" />Арилгах
        </button>
      </div>
    </div>
  </AdminNavbar>

  <div
    v-if="showModal"
    @click="closeModal"
    class="fixed inset-0 bg-black bg-opacity-75 z-[1000] overflow-auto"
  >
    <div class="flex justify-center items-start min-h-screen py-10">
      <img :src="currentImage" class="w-2/3" alt="Agenda Image" />
    </div>
  </div>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import adminMWInstance from "@/lib/adminMWInstance";
import { onMounted, ref, watch } from "vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import PaginationCart from "@/components/ui/PaginationCart.vue";

registerAllModules();

export default {
  name: "MWUpdateAgenda",
  components: {
    AdminNavbar,
    HotTable,
    SpinLoading,
    PaginationCart,
  },
  setup() {
    //EDIT MODE
    const editingMode = ref(false);
    const setEditMode = () => {
      editingMode.value = true;
    };

    const cancelEditHandler = () => {
      editingMode.value = false;
      isSubmitted.value = false;
      resetForm();
    };

    const agenda = ref([]);
    const events = ref([]);
    const languages = ref([]);
    const eventDates = ref([]);
    const isLoading = ref(false);
    const selectedEvent = ref(3);
    const selectedLanguage = ref("all");

    const dataPerPage = ref(10);
    const totalAgendaCount = ref(null);
    const totalPages = ref(1);
    const currentPage = ref(1);

    const form = ref({
      id: null,
      event_date_id: null,
      language_id: null,
    });

    const defaultImage = require("@/assets/svg/image-not-found-icon.svg");
    const imageSrc = ref(defaultImage);
    const mainImage = ref("");

    const onImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        imageSrc.value = URL.createObjectURL(file);
        mainImage.value = file;
      } else {
        imageSrc.value = defaultImage;
        mainImage.value = null;
      }
    };

    const fetchEvents = async () => {
      try {
        const eventRes = await adminMWInstance.get("/get/event");
        if (eventRes.status === 200) {
          events.value = eventRes.data.event;
        }
        const langRes = await adminMWInstance.get("/get/language");
        if (langRes.status === 200) {
          languages.value = langRes.data.result;
        }
      } catch (err) {
        return err;
      }
    };

    const fetchAgendaDates = async () => {
      try {
        const res = await adminMWInstance.get(
          `/get/event-dates?language=${form.value.language_id}`
        );
        if (res.status === 200) {
          eventDates.value = res.data.dates;
        }
      } catch (err) {
        return err;
      }
    };

    const fetchAgendaData = async () => {
      try {
        isLoading.value = true;
        const res = await adminMWInstance.get(
          `/get/agenda?event=${selectedEvent.value}&page=${currentPage.value}&limit=${dataPerPage.value}&language=${selectedLanguage.value}`
        );
        if (res.status === 200) {
          agenda.value = res.data.agenda;
          totalAgendaCount.value = res.data.total;
          totalPages.value = res.data.totalPages;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchEvents();
      await fetchAgendaData();
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchAgendaData();
    };

    watch(
      [
        () => dataPerPage.value,
        () => selectedEvent.value,
        () => selectedLanguage.value,
      ],
      () => {
        currentPage.value = 1;
        fetchAgendaData();
      }
    );

    watch([() => form.value.language_id], () => {
      if (form.value.language_id !== null) {
        fetchAgendaDates();
      }
    });

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const hotSettings = {
      colHeaders: [
        "Үйлдэл",
        "Хөтөлбөрийн огноо",
        "Хөтөлбөрийн өдөр",
        "Хөтөлбөр",
        "Арга хэмжээ",
        "Хэл",
      ],
      colWidths: [200, 200, 150, 250, 150, 80],
      columns: [
        {
          renderer: function (inst, td, row) {
            const currentAgenda = agenda.value[row];
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-2 gap-2 text-white">
                  <button class="flex items-center bg-sky-500 py-1 px-2 rounded edit-btn">
                      <svg class="fill-current w-3 h-3 mr-1" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
                      <span>Засварлах</span>
                  </button>
                  <button class="flex justify-center items-center bg-red-500 py-1 px-2 rounded delete-btn">
                      <svg class="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
                      <span>Устгах</span>
                  </button>
              </div>`;

            const editButton = td.querySelector(".edit-btn");
            if (editButton) {
              editButton.addEventListener("click", () => {
                editAgenda(currentAgenda);
              });
            }

            const deleteButton = td.querySelector(".delete-btn");
            if (deleteButton) {
              deleteButton.addEventListener("click", () => {
                deleteAgenda(currentAgenda.id);
              });
            }
          },
          readOnly: true,
        },
        {
          data: "event_date",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "day",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          renderer: function (inst, td, row) {
            const currentImage = agenda.value[row].agenda_image;
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="relative flex items-center justify-center p-2">
              <button class="show-btn">
                <img src="${currentImage}" alt="agenda" class="w-52 object-fit" />
              </button>
            </div>`;

            const showButton = td.querySelector(".show-btn");
            if (showButton) {
              showButton.addEventListener("click", () => {
                openModal(currentImage);
              });
            }
          },
          readOnly: true,
        },
        {
          data: "event",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "language",
          readOnly: true,
          renderer: commonRenderer,
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "70vh",
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const editAgenda = (agenda) => {
      imageSrc.value = agenda.agenda_image;
      mainImage.value = agenda.agenda_image;
      form.value = {
        id: agenda.id,
        event_date_id: agenda.event_date_id,
        language_id: agenda.language_id,
      };

      editingMode.value = true;
    };

    const deleteAgenda = async (id) => {
      if (!window.confirm(`Хөтөлбөрийг устгах уу?`)) {
        return;
      }

      try {
        const res = await adminMWInstance.delete(`/delete/agenda?id=${id}`);
        if (res.status === 200) {
          await fetchAgendaData();
        }
      } catch (err) {
        return err;
      }
    };

    const resetForm = () => {
      imageSrc.value = defaultImage;
      mainImage.value = null;
      form.value = {
        id: null,
        event_date_id: null,
        language_id: null,
      };
    };

    const isSubmitted = ref(false);
    const validateForm = () => {
      return (
        mainImage.value && form.value.event_date_id && form.value.language_id
      );
    };

    const uploadAgenda = async () => {
      try {
        isSubmitted.value = true;
        if (!validateForm()) {
          return;
        }

        const formData = new FormData();
        formData.append("image", mainImage.value);
        formData.append("event_date_id", form.value.event_date_id);
        formData.append("language_id", form.value.language_id);

        if (form.value.id) {
          formData.append("id", form.value.id);
          const res = await adminMWInstance.post("/update/agenda", formData);
          if (res.status === 200) {
            await fetchAgendaData();
            isSubmitted.value = false;
            resetForm();
            editingMode.value = false;
          }
        } else {
          const res = await adminMWInstance.post("/insert/agenda", formData);
          if (res.status === 200) {
            await fetchAgendaData();
            isSubmitted.value = false;
            resetForm();
            editingMode.value = false;
          }
        }
      } catch (err) {
        return err;
      }
    };

    const showModal = ref(false);
    const currentImage = ref(null);

    const openModal = (imageUrl) => {
      currentImage.value = imageUrl;
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
      currentImage.value = null;
    };

    return {
      editingMode,
      setEditMode,
      cancelEditHandler,
      mainImage,
      onImageChange,
      agenda,
      selectedEvent,
      currentPage,
      totalAgendaCount,
      totalPages,
      hotSettings,
      events,
      onPageChanged,
      dataPerPage,
      form,
      uploadAgenda,
      isSubmitted,
      imageSrc,
      eventDates,
      resetForm,
      isLoading,
      selectedLanguage,
      languages,
      currentImage,
      showModal,
      openModal,
      closeModal,
    };
  },
};
</script>
