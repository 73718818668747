<template>
  <div
    class="hidden sm:flex flex-col fixed left-0 top-1/2 transform -translate-y-1/2 z-10 text-white"
  >
    <a href="tel:+976 2070110163" :class="`${backgroundColor} p-2 text-lg`">
      <font-awesome-icon icon="fa-solid fa-phone" />
    </a>
    <a
      href="https://api.whatsapp.com/send?phone=+976 2070110163"
      class="bg-[#25D366] p-2 text-2xl"
    >
      <font-awesome-icon icon="fa-brands fa-whatsapp" />
    </a>
  </div>
</template>

<script>
export default {
  name: "QuickContact",
  props: {
    backgroundColor: {
      type: String,
      default: "bg-green",
    },
  },
  setup() {},
};
</script>
