<template>
  <MWBackground>
    <div class="flex justify-center w-full py-10 z-10">
      <main
        class="min-h-screen w-full min-[1200px]:w-4/5 max-[1199px]:px-5 text-white"
      >
        <MWOrganiserLogos class="flex justify-start items-center w-full" />

        <section>
          <div
            class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-between items-center gap-3 lg:gap-10 mt-10"
          >
            <button
              v-for="item in agenda"
              :key="item.event_date_id"
              @click="selectDay(item.event_date_id)"
              class="block w-full p-2 text-center"
              :class="
                selectedDay === item.event_date_id
                  ? 'bg-[#085A43]'
                  : 'bg-[#009269] hover:bg-[#085A43]'
              "
            >
              <p class="uppercase font-bold">{{ item.day }}</p>
              <p class="text-xs">{{ item.event_date }}</p>
            </button>
          </div>

          <div v-if="!isLoading && images.length > 0">
            <div
              class="grid grid-cols-1 min-[500px]:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-5 mt-10"
            >
              <img
                v-for="(gallery, index) in images"
                :key="index"
                :src="gallery.image"
                @click="showSwiper(index)"
                alt="image"
                class="cursor-pointer"
              />
            </div>

            <div
              v-if="!isAllLoaded"
              class="relative flex justify-center mt-6 mb-10"
            >
              <button
                @click="loadMore"
                class="bg-[#009269] border border-transparent p-2 rounded hover:border-white"
              >
                Цааш үзэх
              </button>
              <div
                class="absolute bottom-[-10px] left-50 flex justify-center items-center text-[#009269] rounded-full h-5 w-5 bg-white"
              >
                <font-awesome-icon
                  icon="fa-solid fa-chevron-down"
                  class="text-xs"
                />
              </div>
            </div>
          </div>

          <div v-else class="flex justify-center items-center h-[40vh]">
            <BarLoading />
          </div>
        </section>
      </main>
    </div>
  </MWBackground>

  <MWImageSwiper
    v-if="isSwiperVisible"
    :images="images"
    :startIndex="swiperStartIndex"
    @close="hideSwiper"
  />
</template>

<script>
import MWBackground from "@/components/MiningWeek/MW2024/MWBackground.vue";
import MWOrganiserLogos from "@/components/MiningWeek/MW2024/MWOrganiserLogos.vue";
import BarLoading from "@/components/ui/BarLoading.vue";
import MWImageSwiper from "@/containers/MiningWeek/MWImageSwiper.vue";
import clientMWInstance from "@/lib/clientMWInstance";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "MWPhotos",
  components: {
    MWBackground,
    MWOrganiserLogos,
    BarLoading,
    MWImageSwiper,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const selectedDay = ref(null);
    const selectedImageIndex = ref(null);

    const selectDay = (event_date_id) => {
      selectedDay.value = event_date_id;
      resetGallery();
      fetchGallery();
    };

    const isSwiperVisible = ref(false);
    const swiperStartIndex = ref(0);

    const showSwiper = (index) => {
      swiperStartIndex.value = index;
      setTimeout(() => {
        isSwiperVisible.value = true;
      }, 100);
    };

    const hideSwiper = () => {
      isSwiperVisible.value = false;
    };

    const getGalleryId = (eventDateId) => {
      if ([1, 5].includes(eventDateId)) return 1;
      if ([2, 6].includes(eventDateId)) return 2;
      if ([3, 7].includes(eventDateId)) return 3;
      if ([4, 8].includes(eventDateId)) return 4;
      return 1;
    };

    const agenda = ref([]);
    const fetchAgenda = async () => {
      const language = lang.value === "mn" ? 1 : 2;
      const agendaRes = await clientMWInstance.get(
        `/get/agenda?event=${3}&language=${language}`
      );
      if (agendaRes.status === 200) {
        agenda.value = agendaRes.data.agenda;
        if (agenda.value.length > 0) {
          selectedDay.value = agenda.value[0].event_date_id;
        }
      }
    };

    const isLoading = ref(false);
    const images = ref([]);
    const offset = ref(0);
    const limit = 12;
    const isAllLoaded = ref(false);

    const fetchGallery = async () => {
      try {
        isLoading.value = true;
        const id = getGalleryId(selectedDay.value);
        const res = await clientMWInstance.get(
          `/get/gallery-by-date?id=${id}&limit=${limit}&offset=${offset.value}`
        );
        if (res.status === 200) {
          if (res.data.images.length < limit) {
            isAllLoaded.value = true;
          }
          images.value.push(...res.data.images);
          offset.value += limit;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    const loadMore = () => {
      fetchGallery();
    };

    const resetGallery = () => {
      images.value = [];
      offset.value = 0;
      isAllLoaded.value = false;
    };

    onMounted(async () => {
      await fetchAgenda();
      await fetchGallery();
    });

    watch([lang], () => {
      fetchAgenda();
    });

    return {
      isLoading,
      images,
      agenda,
      selectDay,
      selectedDay,
      loadMore,
      isAllLoaded,
      selectedImageIndex,
      isSwiperVisible,
      swiperStartIndex,
      showSwiper,
      hideSwiper,
    };
  },
};
</script>
