import { createStore } from "vuex";
import language from "./language";
import admin from "./admin";
import client from "./client";

export default createStore({
  namespaced: true,
  modules: {
    language,
    admin,
    client,
  },
});
