<template>
  <MWBackground>
    <div class="flex justify-center w-full py-10 z-10 text-white">
      <div
        class="flex max-sm:flex-col w-full min-[1200px]:w-4/5 max-[1199px]:px-3 min-h-screen gap-5"
      >
        <!-- days -->
        <div
          class="grid grid-cols-2 sm:grid-cols-1 w-full sm:w-1/3 lg:w-1/5 h-fit gap-3 sm:text-nowrap"
        >
          <button
            v-for="item in agenda"
            :key="item.event_date_id"
            @click="selectDay(item.event_date_id)"
            class="block w-full p-1 sm:p-1 sm:p-3 text-start"
            :class="
              selectedDay === item.event_date_id
                ? 'bg-[#085A43]'
                : 'bg-[#009269] hover:bg-[#085A43]'
            "
          >
            <p class="uppercase font-bold">
              {{ item.day }}
            </p>
            <p class="text-xs">
              {{ item.event_date }}
            </p>
          </button>
        </div>

        <div class="w-full sm:w-2/3 lg:w-4/5">
          <div class="space-y-5">
            <img
              v-for="agendaItem in currentAgendaItems"
              :key="agendaItem.id"
              :src="agendaItem.agenda_image"
              alt="Agenda Image"
              class="w-full h-auto"
            />
          </div>
        </div>
      </div>
    </div>
  </MWBackground>
</template>

<script>
import MWBackground from "@/components/MiningWeek/MW2024/MWBackground.vue";
import clientMWInstance from "@/lib/clientMWInstance";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "MWAgenda",
  components: {
    MWBackground,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const selectedDay = ref(null);
    const selectDay = (event_date_id) => {
      selectedDay.value = event_date_id;
    };

    const isLoading = ref(false);
    const agenda = ref([]);

    const fetchAgenda = async () => {
      try {
        isLoading.value = true;
        const language = lang.value === "mn" ? 1 : 2;
        const res = await clientMWInstance.get(
          `/get/agenda?event=${3}&language=${language}`
        );
        if (res.status === 200) {
          agenda.value = res.data.agenda;
          if (agenda.value.length > 0) {
            selectedDay.value = agenda.value[0].event_date_id;
          }
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchAgenda();
    });

    watch([() => lang.value], () => {
      fetchAgenda();
    });

    const currentAgendaItems = computed(() => {
      const selectedAgendaItem = agenda.value.find(
        (item) => item.event_date_id === selectedDay.value
      );
      return selectedAgendaItem ? selectedAgendaItem.agenda_items : [];
    });

    return {
      lang,
      selectedDay,
      selectDay,
      agenda,
      isLoading,
      currentAgendaItems,
    };
  },
};
</script>
