<template>
  <header
    class="flex justify-center items-center bg-[#001F3D] sticky top-0 z-20"
  >
    <div
      class="flex justify-between items-center w-full min-[1200px]:w-4/5 border-b-2 border-white text-xs px-3 lg:px-5"
    >
      <router-link to="/mw">
        <img
          src="@/assets/images/miningWeek2025/Miningweek 2025 logo.png"
          alt="logo"
          class="w-[8rem] lg:w-[10rem] object-contain my-3"
        />
      </router-link>
      <button
        @click="toggleDrawer()"
        class="block md:hidden border border-white rounded px-2 py-1"
      >
        <font-awesome-icon icon="fa-solid fa-bars" class="text-xl text-white" />
      </button>

      <!-- for large screen -->
      <nav class="hidden md:block">
        <ul class="flex justify-between gap-3 max-lg:ml-3 lg:gap-8 text-white">
          <li
            v-for="(link, index) in categories"
            :key="index"
            class="flex justify-center items-center border-b-4 text-center hover:border-[#009269]"
            :class="
              link.to === isRoute ? 'border-[#009269]' : 'border-transparent'
            "
          >
            <router-link :to="link.to">
              {{ lang === "mn" ? link.category : link.category_en }}
            </router-link>
          </li>
          <li class="flex justify-center items-center gap-1 ml-3">
            <button
              @click="toggleLanguage('en')"
              class="flex justify-center items-center h-[3.5rem] border-b-4 border-transparent"
              :class="
                lang === 'en' ? 'text-[#009269]' : 'hover:border-[#009269]'
              "
            >
              EN
            </button>
            /
            <button
              @click="toggleLanguage('mn')"
              class="flex justify-center items-center h-[3.5rem] border-b-4 border-transparent"
              :class="
                lang === 'mn' ? 'text-[#009269]' : 'hover:border-[#009269]'
              "
            >
              MN
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </header>

  <!-- small screen -->
  <Transition name="fade" class="md:hidden">
    <main v-if="isDrawerOpen" class="w-full h-full">
      <div
        @click="toggleDrawer()"
        class="fixed inset-0 w-full h-full bg-black bg-opacity-50 z-20"
      ></div>

      <div
        class="fixed top-0 right-0 z-30 h-screen w-[18rem] bg-[#001F3D] text-white text-sm p-5"
      >
        <button @click="toggleDrawer()" class="absolute top-3 right-3">
          <font-awesome-icon icon="fa-solid fa-xmark" class="text-xl" />
        </button>

        <ul class="mt-10">
          <li
            v-for="(link, index) in categories"
            :key="index"
            class="border-b border-slate-200 py-3"
            :class="link.to === isRoute ? 'text-[#009269]' : 'text-white'"
          >
            <router-link :to="link.to" @click="toggleDrawer()">
              {{ lang === "mn" ? link.category : link.category_en }}
            </router-link>
          </li>
          <li class="flex gap-2 border-b border-slate-200 py-3">
            <button
              @click="toggleLanguage('en')"
              :class="
                lang === 'en' ? 'text-[#009269]' : 'hover:border-[#009269]'
              "
            >
              EN
            </button>
            /
            <button
              @click="toggleLanguage('mn')"
              :class="
                lang === 'mn' ? 'text-[#009269]' : 'hover:border-[#009269]'
              "
            >
              MN
            </button>
          </li>
        </ul>
      </div>
    </main>
  </Transition>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "MWNavbar",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const toggleLanguage = (value) => {
      store.dispatch("toggleLanguage", value);
    };

    const categories = ref([
      {
        category: "Нүүр",
        category_en: "Home",
        to: "/mw",
      },
      {
        category: "Хөтөлбөр",
        category_en: "Agenda",
        to: "/mw/agenda",
      },
      {
        category: "Бидний тухай",
        category_en: "About us",
        to: "/mw/about",
      },
      {
        category: "Илтгэл",
        category_en: "Presentation",
        to: "/mw/presentation",
      },
      {
        category: "Зургийн цомог",
        category_en: "Gallery",
        to: "/mw/gallery",
      },
      {
        category: "Mining Week 2023",
        category_en: "Mining Week 2023",
        to: "/mw/2023",
      },
      {
        category: "Холбоо барих",
        category_en: "Contact us",
        to: "/mw/contact",
      },
    ]);

    const isDrawerOpen = ref(false);
    const toggleDrawer = () => {
      isDrawerOpen.value = !isDrawerOpen.value;
    };

    const route = useRoute();
    const isRoute = computed(() => route.path);

    return {
      categories,
      lang,
      toggleLanguage,
      isDrawerOpen,
      toggleDrawer,
      isRoute,
    };
  },
};
</script>
